/*
 * CKEditor 5 (v37.1.0) content styles.
 * Generated on Fri, 28 Apr 2023 12:36:40 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root
{
    --ck-color-image-caption-background: rgb(247, 247, 247);
    --ck-color-image-caption-text: rgb(51, 51, 51);
    --ck-color-mention-background: rgba(153, 0, 48, 0.1);
    --ck-color-mention-text: rgb(153, 0, 48);
    --ck-color-table-caption-background: rgb(247, 247, 247);
    --ck-color-table-caption-text: rgb(51, 51, 51);
    --ck-highlight-marker-blue: rgb(0, 166, 255);
    --ck-highlight-marker-green: rgb(98, 249, 98);
    --ck-highlight-marker-pink: rgb(252, 120, 153);
    --ck-highlight-marker-yellow: rgb(253, 253, 119);
    --ck-highlight-pen-green: rgb(18, 138, 0);
    --ck-highlight-pen-red: rgb(231, 19, 19);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote
{
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px rgb(204, 204, 204);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote
{
    border-left: 0;
    border-right: solid 5px rgb(204, 204, 204);
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code
{
    background-color: rgba(199, 199, 199, 0.3);
    padding: .15em;
    border-radius: 2px;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny
{
    font-size: .7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small
{
    font-size: .85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big
{
    font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge
{
    font-size: 1.8em;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow
{
    background-color: var(--ck-highlight-marker-yellow);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green
{
    background-color: var(--ck-highlight-marker-green);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink
{
    background-color: var(--ck-highlight-marker-pink);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue
{
    background-color: var(--ck-highlight-marker-blue);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red
{
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green
{
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image
{
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img
{
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline
{
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture
{
    display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img
{
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized
{
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img
{
    width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption
{
    display: block;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption
{
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right
{
    max-width: calc(100% - var(--ck-image-style-spacing));
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right
{
    clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side
{
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left
{
    float: left;
    margin-right: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center
{
    margin-left: auto;
    margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right
{
    float: right;
    margin-left: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right
{
    margin-right: 0;
    margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left
{
    margin-left: 0;
    margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side
{
    margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right
{
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left
{
    margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right
{
    margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list
{
    list-style: none;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li
{
    margin-bottom: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list
{
    margin-top: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input
{
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before
{
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid rgb(51, 51, 51);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after
{
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before
{
    background: rgb(38, 171, 51);
    border-color: rgb(38, 171, 51);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after
{
    border-color: rgb(255, 255, 255);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description
{
    vertical-align: middle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol
{
    list-style-type: decimal ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol
{
    list-style-type: lower-latin ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol
{
    list-style-type: lower-roman ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol
{
    list-style-type: upper-latin ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol
{
    list-style-type: upper-roman ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul
{
    list-style-type: disc ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul
{
    list-style-type: circle ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul
{
    list-style-type: square ;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul
{
    list-style-type: square ;
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media
{
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break
{
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after
{
    content: '';
    position: absolute;
    border-bottom: 2px dashed rgb(196, 196, 196);
    width: 100%;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label
{
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table
{
    margin: 0.9em auto;
    display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table
{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double rgb(179, 179, 179);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th
{
    min-width: 2em;
    padding: .4em;
    border: 1px solid rgb(191, 191, 191);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th
{
    font-weight: bold;
    background: rgba(0, 0, 0, 0.05);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th
{
    text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th
{
    text-align: left;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized
{
    table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table
{
    overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th
{
    position: relative;
}
/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption
{
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre
{
    padding: 1em;
    color: rgb(53, 53, 53);
    background: rgba(199, 199, 199, 0.3);
    border: 1px solid rgb(196, 196, 196);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code
{
    background: unset;
    padding: 0;
    border-radius: 0;
}
/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr
{
    margin: 15px 0;
    height: 4px;
    background: rgb(222, 222, 222);
    border: 0;
}
/* @ckeditor/ckeditor5-mention/theme/mention.css */
.ck-content .mention
{
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}
@media print
{
    /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break
    {
        padding: 0;
    }
    /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after
    {
        display: none;
    }
}


.ck-rounded-corners .ck.ck-toolbar, .ck.ck-toolbar.ck-rounded-corners
{
    border-radius: 0 !important;
}

.ck.ck-toolbar
{
   background: transparent !important;
   border-top: none !important;
   border-left: none !important;
   border-right: none !important;
   border-bottom-width: 0.1rem !important;
   border-color: var(--ui-border-color) !important;
   padding: 0 var(--ck-spacing-small) !important;
}


/*********************************************************************
    CSS AÑADIDO
 **********************************************************************/

h1, .ck-heading_heading1
{
    font-size: 26px;
}

h2, .ck-heading_heading2
{
    font-size: 24px;
}

h3, .ck-heading_heading3
{
    font-size: 22px;
}

h4, .ck-heading_heading1
{
    font-size: 20px;
}

h5, .ck-heading_heading2
{
    font-size: 18px;
}

h6, .ck-heading_heading3
{
    font-size: 16px;
}

.ck-editor__editable_inline {
    padding: 0 30px !important;
}

[dir=ltr] .ck.ck-splitbutton.ck-splitbutton_open>.ck-splitbutton__action, [dir=ltr] .ck.ck-splitbutton:hover>.ck-splitbutton__action
 {
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
}

.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover
{
    background: var(--ck-color-button-default-hover-background);
}

.ck-rounded-corners .ck.ck-button, .ck-rounded-corners a.ck.ck-button, .ck.ck-button.ck-rounded-corners, a.ck.ck-button.ck-rounded-corners
{
    border-radius: var(--ck-border-radius);
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all
{
    border-collapse: collapse;
    color: var(--ck-color-text);
    cursor: auto;
    float: none;
    font: normal normal normal var(--ck-font-size-base)/var(--ck-line-height-base) var(--ck-font-face);
    text-align: left;
    white-space: nowrap;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset, .ck.ck-reset_all
{
    word-wrap: break-word;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    text-decoration: none;
    transition: none;
    vertical-align: middle;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset, .ck.ck-reset_all
{
    box-sizing: border-box;
    height: auto;
    position: static;
    width: auto;
}

[dir=ltr] .ck.ck-splitbutton.ck-splitbutton_open>.ck-splitbutton__arrow:not(.ck-disabled):after, [dir=ltr] .ck.ck-splitbutton:hover>.ck-splitbutton__arrow:not(.ck-disabled):after
{
    left: -1px;
}

.ck.ck-splitbutton.ck-splitbutton_open>.ck-splitbutton__arrow:not(.ck-disabled):after, .ck.ck-splitbutton:hover>.ck-splitbutton__arrow:not(.ck-disabled):after
{
    background-color: var(--ck-color-split-button-hover-border);
    content: "";
    height: 100%;
    position: absolute;
    width: 1px;
    left: 0px;
}

[dir=ltr] .ck.ck-splitbutton>.ck-splitbutton__arrow
{
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
}

.ck-rounded-corners .ck.ck-button, .ck-rounded-corners a.ck.ck-button, .ck.ck-button.ck-rounded-corners, a.ck.ck-button.ck-rounded-corners
{
    border-radius: var(--ck-border-radius);
}
