/********************************************************************
  @FONT-FACE
 ********************************************************************/
 @font-face
{
    font-family  : "material-icons";
    font-display : swap;
    font-style   : normal;
    font-weight  : normal;

    src : url("../assets/fonts/material/material-icons.eot");
    src : url('../assets/fonts/material/material-icons.woff2')             format('woff2 supports variations'),
          url('../assets/fonts/material/material-icons.woff2')             format('woff2-variations'),
          url('../assets/fonts/material/material-icons.woff2')             format('woff2'),
          url("../assets/fonts/material/material-icons.ttf")               format("truetype"),
          url("../assets/fonts/material/material-icons.woff")              format("woff"),
          url("../assets/fonts/material/material-icons.eot?#iefix")        format("embedded-opentype"),
          url("../assets/fonts/material/material-icons.svg#materialicons") format("svg");
}


@font-face
{
    font-family  : "roboto-mono";
    font-display : swap;
    font-style   : normal;
    font-weight  : 100 1000;
    font-stretch : 25% 151%;
    font-style   : oblique -10deg 0deg;

    src : url("../assets/fonts/roboto/mono/roboto-mono.eot");
    src : url('../assets/fonts/roboto/mono/roboto-mono.woff2')          format('woff2 supports variations'),
          url('../assets/fonts/roboto/mono/roboto-mono.woff2')          format('woff2-variations'),
          url('../assets/fonts/roboto/mono/roboto-mono.woff2')          format('woff2'),
          url("../assets/fonts/roboto/mono/roboto-mono.ttf")            format("truetype"),
          url("../assets/fonts/roboto/mono/roboto-mono.woff")           format("woff"),
          url("../assets/fonts/roboto/mono/roboto-mono.eot?#iefix")     format("embedded-opentype"),
          url("../assets/fonts/roboto/mono/roboto-mono.svg#robotomono") format("svg");
}


@font-face
{
    font-family  : "roboto-flex";
    font-display : swap;
    font-style   : normal;
    font-weight  : 100 1000;
    font-stretch : 25% 151%;
    font-style   : oblique -10deg 0deg;

    src : url("../assets/fonts/roboto/flex/roboto-flex.eot");
    src : url('../assets/fonts/roboto/flex/roboto-flex.woff2')          format('woff2 supports variations'),
          url('../assets/fonts/roboto/flex/roboto-flex.woff2')          format('woff2-variations'),
          url('../assets/fonts/roboto/flex/roboto-flex.woff2')          format('woff2'),
          url("../assets/fonts/roboto/flex/roboto-flex.ttf")            format("truetype"),
          url("../assets/fonts/roboto/flex/roboto-flex.woff")           format("woff"),
          url("../assets/fonts/roboto/flex/roboto-flex.eot?#iefix")     format("embedded-opentype"),
          url("../assets/fonts/roboto/flex/roboto-flex.svg#robotoflex") format("svg");
}

/*
@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : normal;
    font-weight  : 100;

    src : url("../assets/fonts/roboto/roboto-thin.eot");
    src : url("../assets/fonts/roboto/roboto-thin.eot?#iefix")     format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-thin.woff2')          format('woff2'),
          url("../assets/fonts/roboto/roboto-thin.woff")           format("woff"),
          url("../assets/fonts/roboto/roboto-thin.ttf")            format("truetype"),
          url("../assets/fonts/roboto/roboto-thin.svg#robotothin") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : italic;
    font-weight  : 100;

    src : url("../assets/fonts/roboto/roboto-thin-italic.eot");
    src : url("../assets/fonts/roboto/roboto-thin-italic.eot?#iefix")           format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-thin-italic.woff2')                format('woff2'),
          url("../assets/fonts/roboto/roboto-thin-italic.woff")                 format("woff"),
          url("../assets/fonts/roboto/roboto-thin-italic.ttf")                  format("truetype"),
          url("../assets/fonts/roboto/roboto-thin-italic.svg#robotothinitalic") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : normal;
    font-weight  : 300;

    src : url("../assets/fonts/roboto/roboto-light.eot");
    src : url("../assets/fonts/roboto/roboto-light.eot?#iefix")      format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-light.woff2')           format('woff2'),
          url("../assets/fonts/roboto/roboto-light.woff")            format("woff"),
          url("../assets/fonts/roboto/roboto-light.ttf")             format("truetype"),
          url("../assets/fonts/roboto/roboto-light.svg#robotolight") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : italic;
    font-weight  : 300;

    src : url("../assets/fonts/roboto/roboto-light-italic.eot");
    src : url("../assets/fonts/roboto/roboto-light-italic.eot?#iefix")            format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-light-italic.woff2')                 format('woff2'),
          url("../assets/fonts/roboto/roboto-light-italic.woff")                  format("woff"),
          url("../assets/fonts/roboto/roboto-light-italic.ttf")                   format("truetype"),
          url("../assets/fonts/roboto/roboto-light-italic.svg#robotolightitalic") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : normal;
    font-weight  : 400;
    font-stretch : 25% 151%;

    src : url("../assets/fonts/roboto/roboto-regular.eot");
    src : url("../assets/fonts/roboto/roboto-regular.eot?#iefix")        format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-regular.woff2')             format('woff2'),
          url("../assets/fonts/roboto/roboto-regular.woff")              format("woff"),
          url("../assets/fonts/roboto/roboto-regular.ttf")               format("truetype"),
          url("../assets/fonts/roboto/roboto-regular.svg#robotoregular") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : italic;
    font-weight  : 400;

    src : url("../assets/fonts/roboto/roboto-italic.eot");
    src : url("../assets/fonts/roboto/roboto-italic.eot?#iefix")       format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-italic.woff2')            format('woff2'),
          url("../assets/fonts/roboto/roboto-italic.woff")             format("woff"),
          url("../assets/fonts/roboto/roboto-italic.ttf")              format("truetype"),
          url("../assets/fonts/roboto/roboto-italic.svg#robotoitalic") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : normal;
    font-weight  : 500;

    src : url("../assets/fonts/roboto/roboto-medium.eot");
    src : url("../assets/fonts/roboto/roboto-medium.eot?#iefix")       format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-medium.woff2')            format('woff2'),
          url("../assets/fonts/roboto/roboto-medium.woff")             format("woff"),
          url("../assets/fonts/roboto/roboto-medium.ttf")              format("truetype"),
          url("../assets/fonts/roboto/roboto-medium.svg#robotomedium") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : italic;
    font-weight  : 500;

    src : url("../assets/fonts/roboto/roboto-medium-italic.eot");
    src : url("../assets/fonts/roboto/roboto-medium-italic.eot?#iefix")             format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-medium-italic.woff2')                  format('woff2'),
          url("../assets/fonts/roboto/roboto-medium-italic.woff")                   format("woff"),
          url("../assets/fonts/roboto/roboto-medium-italic.ttf")                    format("truetype"),
          url("../assets/fonts/roboto/roboto-medium-italic.svg#robotomediumitalic") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : normal;
    font-weight  : 700;

    src : url("../assets/fonts/roboto/roboto-bold.eot");
    src : url("../assets/fonts/roboto/roboto-bold.eot?#iefix")     format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-bold.woff2')          format('woff2'),
          url("../assets/fonts/roboto/roboto-bold.woff")           format("woff"),
          url("../assets/fonts/roboto/roboto-bold.ttf")            format("truetype"),
          url("../assets/fonts/roboto/roboto-bold.svg#robotobold") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : italic;
    font-weight  : 700;

    src : url("../assets/fonts/roboto/roboto-bold-italic.eot");
    src : url("../assets/fonts/roboto/roboto-bold-italic.eot?#iefix")           format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-bold-italic.woff2')                format('woff2'),
          url("../assets/fonts/roboto/roboto-bold-italic.woff")                 format("woff"),
          url("../assets/fonts/roboto/roboto-bold-italic.ttf")                  format("truetype"),
          url("../assets/fonts/roboto/roboto-bold-italic.svg#robotobolditalic") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : normal;
    font-weight  : 900;

    src : url("../assets/fonts/roboto/roboto-black.eot");
    src : url("../assets/fonts/roboto/roboto-black.eot?#iefix")      format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-black.woff2')           format('woff2'),
          url("../assets/fonts/roboto/roboto-black.woff")            format("woff"),
          url("../assets/fonts/roboto/roboto-black.ttf")             format("truetype"),
          url("../assets/fonts/roboto/roboto-black.svg#robotoblack") format("svg");
}


@font-face
{
    font-family  : "roboto";
    font-display : swap;
    font-style   : italic;
    font-weight  : 900;

    src : url("../assets/fonts/roboto/roboto-black-italic.eot");
    src : url("../assets/fonts/roboto/roboto-black-italic.eot?#iefix")            format("embedded-opentype"),
          url('../assets/fonts/roboto/roboto-black-italic.woff2')                 format('woff2'),
          url("../assets/fonts/roboto/roboto-black-italic.woff")                  format("woff"),
          url("../assets/fonts/roboto/roboto-black-italic.ttf")                   format("truetype"),
          url("../assets/fonts/roboto/roboto-black-italic.svg#robotoblackitalic") format("svg");
} */
