/**********************************************************************
    UI ANALYTICS
 **********************************************************************/


.ui-chart circle, .ui-chart path[fill="#0011cc"], .ui-chart text[text-anchor]
{
    cursor : pointer !important;
}


.ui-chart path
{
    opacity : 0.899 !important;
}


.ui-chart[type="PieChart"] path[stroke="#636363"][fill="none"],
.ui-chart[type="PieChart"] path[stroke="#636363"][fill="none"] + circle[stroke="none"][fill="#636363"]
{
    stroke         : var(--ui-icon-color);
    stroke-width   : 1 !important;
    stroke-opacity : 0.766 !important;
}

.ui-chart[type="PieChart"] path[stroke="#636363"][fill="none"] + circle[stroke="none"][fill="#636363"]
{
    stroke-width : 4.56 !important;
}


.ui-chart[type="PieChart"] path + text[font-weight="bold"]
{
    font-weight : 500 !important;
}


.ui-chart[type="PieChart"] path + text[font-weight="bold"][fill="#ffffff"]
{
    text-shadow : 0.1rem 0.1rem 0.2rem rgba(var(--ui-colors-neutral-black), 0.56) !important;
}


.ui-chart text[stroke-width="3"]
{
    stroke       : rgba(var(--ui-colors-neutral-white), 0.756) !important;
    stroke-width : 3 !important;
    text-shadow  : 0 0 0.5rem rgba(var(--ui-colors-neutral-white), 0.96);
}


.ui-chart text[stroke-width="3"] + text, .ui-chart text[stroke-width="3"]
{
    font-weight    : 500 !important;
    color          : var(--ui-font-light-color) !important;
    letter-spacing : -0.01618rem;
}


.ui-chart text[fill="#0011cc"], .ui-chart path[fill="#0011cc"]
{
    fill : var(--ui-icon-color) !important;
}


.ui-chart svg > g:last-child  > g:last-child
{
    pointer-events: none !important;
}


.ui-chart svg > g:first-of-type g
{
    cursor: pointer !important;
}


.ui-chart .google-visualization-tooltip
{
    background     : rgba(var(--ui-colors-neutral-white), 0.954) !important;
    box-shadow     : var(--ui-shadow-card) !important;
    border-radius  : var(--ui-border-radius) !important;
    border         : none !important;
    pointer-events : none !important;
}


.ui-chart .google-visualization-tooltip *
{
    font-size   : 0.966em !important;
    line-height : 100% !important;
}


@media (max-width: 1023px)
{
    .ui-chart .google-visualization-tooltip *
    {
        font-size : 0.9em !important;
    }
}


.ui-chart .google-visualization-tooltip *[style*="font-weight: bold;"]
{
    font-weight : 500 !important;
}


.ui-chart .google-visualization-tooltip .google-visualization-tooltip-item-list
{
    float  : left !important;
    margin : 1.333em 0 !important;
}


.ui-chart .google-visualization-tooltip .google-visualization-tooltip-item-list .google-visualization-tooltip-item
{
    width   : 100% !important;
    float   : left !important;
    clear   : both !important;
    margin  : 0.586em 0 !important;
    padding : 0 1.666em !important;
}


.ui-chart .google-visualization-tooltip .google-visualization-tooltip-item-list .google-visualization-tooltip-item > *
{
    float: left !important;
}


.ui-chart .google-visualization-tooltip-square
{
    border        : none !important;
    width         : 0.5em !important;
    height        : 0.5em !important;
    margin        : 0.216em 0.499em 0 0 !important;
    border-radius : 100% !important;
}


.ui-chart .google-visualization-tooltip .google-visualization-tooltip-item-list .google-visualization-tooltip-item span:last-of-type
{
    float          : right !important;
    margin-left    : 0.399em !important;
}


.ui-chart .google-visualization-tooltip .google-visualization-tooltip-item-list .google-visualization-tooltip-item span:first-of-type
{
    float          : left !important;
    margin-left    : 0 !important;
    text-transform : capitalize !important;
}


/**********************************************************************
    UI ANALYTICS TABLE
 **********************************************************************/


.ui-chart .google-visualization-table,
.ui-chart .google-visualization-table th,
.ui-chart .google-visualization-table td
{
    border-collapse : collapse !important;
    border          : none !important;
    vertical-align  : middle !important;
    text-overflow   : ellipsis !important;
}


.ui-chart .google-visualization-table
{
    position : absolute !important;
    z-index  : 0 !important;
    top      : 0 !important;
    left     : 0 !important;
    width    : 100% !important;
}


.ui-chart  .google-visualization-table *
{
    /* word-spacing : 0.01618rem !important; */
}


.ui-chart  .google-visualization-table *:first-letter
{
    text-transform : uppercase !important;
}


.ui-chart .google-visualization-table > div:first-child
{
    border : none !important;
}


.ui-chart .google-visualization-table th
{
    background-color : rgba(var(--ui-colors-md-50-bluegrey), 0.89) !important;
    border-right     : 0.1rem solid var(--ui-border-color) !important;
    border-bottom    : 0.1rem solid var(--ui-border-color) !important;
    font-weight      : 600 !important;
    padding          : calc(var(--ui-pad) * 3.66) calc(var(--ui-pad) * 8) calc(var(--ui-pad) * 3)  calc(var(--ui-pad) * 8) !important
}


.ui-chart .google-visualization-table th:first-of-type
{
    display         : flex !important;
    flex-direction  : row !important;
    flex-wrap       : nowrap !important;
    justify-content : center !important;
    align-content   : center !important;
    align-items     : center !important;
}


.ui-chart .google-visualization-table th:first-of-type::after
{
    position    : absolute !important;
    font-family : 'material-icons' !important;
    content     : '\e8d5' !important;
    color       : var(--ui-icon-color)!important;
    font-size   : 1.85em !important;
    line-height : 100% !important;
    font-weight : 400 !important;
}


.ui-chart .google-visualization-table-sortind, .ui-chart .unsorted .google-visualization-table-sortind
{
    position     : absolute !important;
    padding-left : calc(var(--ui-pad) * 2) !important;
}


.ui-chart .google-visualization-table th .google-visualization-table-sortind:after
{
    font-family : 'material-icons'!important;
    color       : var(--ui-icon-color)!important;
    font-weight : 600 !important;
    font-size   : 1.05em !important;
}


.ui-chart .sort-descending .google-visualization-table-sortind:after
{
    content : "\f1e3" !important;
}


.ui-chart .sort-ascending .google-visualization-table-sortind:after
{
    content : "\f1e0" !important;
}


.ui-chart .google-visualization-table td
{
    padding       : calc(var(--ui-pad) * 2.33) calc(var(--ui-pad) * 3.66) !important;
    border-bottom : 0.1rem solid var(--ui-border-color) !important;
    border-right  : 0.1rem solid var(--ui-border-color) !important;
}


.ui-chart .tableChartRowNumberCell,
.ui-chart .tableChartTableCell.google-visualization-table-type-number
{
    text-align : right !important;
}


.ui-chart .google-visualization-table tbody tr
{
    transition       : background-color var(--ux-motion-emphasized-accelerate-ms) var(--ux-animation-standard) !important;
    background-color : rgba(var(--ui-colors-md-a200-yellow), 0) !important;
}


.ui-chart .google-visualization-table tbody tr:hover
{
    transition       : none !important;
    background-color : rgba(var(--ui-colors-md-a200-yellow), 0.165) !important;
    cursor           : pointer !important;
}
