/**********************************************************************
    UI SIGNIN
 **********************************************************************/

ui-signin .ux-signin > hr
{
    visibility: hidden;
}


ui-signin .form-wrapper, ui-signin .form-wrapper.form-wrapper-inline
{
    margin : calc(var(--ui-pad) * 1) 0;
}


ui-signin .ui-content
{
    width           : 100%;
    min-height      : 100vh;
    padding         : 0;
    display         : flex;
    flex-direction  : column;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


@media (min-width: 767px)
{
    ui-signin .ui-content
    {
        padding : calc(var(--ui-pad) * 4) calc(var(--ui-pad) * 4) calc(var(--ui-pad) * 2) calc(var(--ui-pad) * 4);
    }
}


ui-signin #ui-signin
{
    width           : 100%;
    height          : 100%;
    min-height      : calc(100vh - (var(--ui-pad) * 2));
    overflow        : initial;
}


@media (min-width: 767px)
{
    ui-signin #ui-signin
    {
        justify-content : center;
        max-width       : 42rem;
        min-height      : fit-content;
        height          : auto;
    }
}


ui-signin .card
{
    min-height       : calc(100vh - var(--ui-header-height));
    box-shadow       : none;
    background-color : rgba(var(--ui-colors-neutral-white),1);
    display          : flex;
    flex-direction   : column;
    flex-wrap        : nowrap;
    justify-content  : space-evenly;
    align-content    : center;
    align-items      : stretch;
}


@media (min-width: 767px)
{
    ui-signin .card
    {
        min-height : min-content;
        box-shadow : var(--ui-shadow-card);
    }
}


ui-signin #ui-signin-wrapper
{
    width            : 100%;
    height           : 100%;
    min-height       : fit-content;
    background-color : rgba(var(--ui-colors-neutral-white), 1);

    display          : flex;
    flex-direction   : column;
    flex-wrap        : nowrap;
    justify-content  : space-evenly;
    align-content    : center;
    align-items      : stretch;
}


@media (min-width: 767px)
{
    ui-signin #ui-signin-wrapper
    {
        height           : auto;
        border-radius    : 0 0 var(--ui-border-radius) var(--ui-border-radius);
        justify-content  : flex-end;
        padding          : var(--ui-pad);
    }
}


ui-signin #ui-signin-imagen
{
    top              : 0.1rem;
    z-index          : 0;
    float            : left;
    width            : 100%;
    height           : auto;
    padding          : 4vh calc(var(--ui-pad) * 10) var(--ui-pad) calc(var(--ui-pad) * 10) ;
    background-color : rgba(var(--ui-colors-neutral-white), 1);
}


@media (min-width: 767px)
{
    ui-signin #ui-signin-imagen
    {
        padding       : calc(var(--ui-pad) * 10) calc(var(--ui-pad) * 10) calc(var(--ui-pad) * 4) calc(var(--ui-pad) * 10);
        border-radius : var(--ui-border-radius) var(--ui-border-radius) 0 0;
    }
}


ui-signin #ui-signin-imagen figure
{
    float           : left;
    width           : 100%;
    height          : auto;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


ui-signin #ui-signin-imagen figure img
{
    width      : auto;
    min-width  : 65%;
    max-width  : 100%;
    max-height : 3.2rem;
    object-fit : contain;
}


ui-signin #ui-signin-form,
ui-signin #ui-signin-form > div,
ui-signin #ui-signin-form > div > div,
ui-signin #ui-signin-form > div > div > div
{
    width : 100%;
}


ui-signin #ui-signin-form
{
    overflow : hidden;
}


ui-signin #ui-signin-form > div
{
    position : relative;
}


ui-signin #ui-signin-form > div > router-outlet ~ *
{
    width            : 100%;
    background-color : rgba(var(--ui-colors-neutral-white), 1);
}


ui-signin #ui-signin-form > div > router-outlet ~ * > *
{
    width : 100%;
}


ui-signin #ui-signin-form,
ui-signin #ui-signin-form > div,
ui-signin #ui-signin-form > div > .router-outlet > *,
ui-signin #ui-signin-form > div > .router-outlet ~ * > *
{
    min-height      : 44rem;
    display         : flex;
    flex-direction  : column;
    flex-wrap       : nowrap;
    justify-content : space-between;
    align-content   : center;
    align-items     : stretch;
}


ui-signin #ui-signin-form > div > .router-outlet > *,
ui-signin #ui-signin-form > div > .router-outlet ~ * > *
{
    justify-content : center;
}


@media (min-width: 767px)
{
    ui-signin #ui-signin-form > div > .router-outlet > *,
    ui-signin #ui-signin-form > div > .router-outlet ~ * > *
    {
        justify-content : space-between;
    }
}


ui-signin .ui-signin-form-button
{
    width           : 100%;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : space-between;
    align-content   : center;
    align-items     : center;
}


ui-signin .ui-signin-form-button > div
{
    width : 50%;
}


ui-signin .ui-signin-form-button > div > a
{
    padding-right: calc(var(--ui-pad) * 2);
}


ui-signin .ui-signin-form-button .form-wrapper-button
{
    padding-top : 0;
    margin      : 0;
}


ui-signin #ui-signin-info
{
    text-align : left;
}


ui-signin #ui-signin-options
{
    height         : var(--ui-header-height);
    padding-top    : 0;
    padding-bottom : 0;
}


@media (min-width: 767px)
{
    ui-signin #ui-signin-options
    {
        padding : var(--ui-pad);
    }
}


ui-signin #ui-signin-options, #ui-signin-options ul
{
    width           : 100%;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-end;
    align-content   : center;
    align-items     : center;
}


ui-signin #ui-signin #ui-signin-options .form-wrapper
{
    width          : 100%;
    max-width      : 8rem;
    padding-top    : 0;
    padding-bottom : 0;
}


ui-signin #ui-signin #ui-signin-options .form-wrapper .form-content
{
    height : 6rem;
}


ui-signin #ui-signin #ui-signin-options ul
{
    width : 100%;
}


ui-signin #ui-signin #ui-signin-options ul *
{
    width      : auto;
    padding    : 0 calc(var(--ui-pad) / 2) 0 calc(var(--ui-pad) * 2);
    color      : var(--ui-font-color);
    text-align : center;
}


/**********************************************************************
    UI ANALYTICS
 **********************************************************************/


.card-analytics-color .ux-spinner-block
{
    background: rgba(var(--ui-colors-neutral-black), 0.166);
}


.ui-chart
{
    width          : 100%;
    height         : 0;
    min-height     : 50vmin;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : flex-start;
}


.ui-chart.gChart-loading, .ui-chart.gChart-loading > div
{
    justify-content : flex-start;
    overflow        : hidden;
}


.ui-chart *
{
    font-family : "roboto-flex" !important;
    font-size   : 0.998em !important;
    white-space : nowrap !important;
}


@media (max-width: 1023px)
{
    .ui-chart
    {
        padding    : calc(var(--ui-pad) * 3);
        min-height : calc(100vmin - (var(--ui-pad) * 8));
    }


    .ui-chart *
    {
       font-size : 0.986em !important;
    }
}


/**********************************************************************
    UI ANALYTICS TABLE
 **********************************************************************/


.card-analytics
{
    width: 100%;
    /* max-width: 44rem; */

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.card-analytics-spn
{
    position: relative;
}


.card-analytics hr
{
    border-color: rgba(var(--ui-color-border), 0.66);
}


.card-analytics .card-analytics-title
{
    width: 100%;
    padding-top: calc(var(--ui-pad) * 3);
    padding-bottom: calc(var(--ui-pad) * 2);
}


.card-analytics .card-analytics-scroll-h
{
    width: auto;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    float: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
}


.card-analytics .card-analytics-scroll-h .cards-analytics-wrapper
{
    width: auto;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
}


.card-analytics-scroll-h .ui-trending
{
    position: relative;
    width: auto;
    float: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;

}


.card-analytics .card-analytics-spn
{
    border-left-width: 0.1rem;
    border-left-color: rgba(var(--ui-color-border), 0.66);
}


.card-analytics .card-analytics-spn:first-of-type
{
    border-left-width: 0;
}


.card-analytics .card-analytics-trending-title
{
    max-width: 24rem;
}


.card-analytics .card-analytics-scroll-h .ui-trending *
{
    line-height: 100%;
    white-space: nowrap;
}


.card-analytics .card-analytics-scroll-h .ui-trending > div
{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.card-analytics .card-analytics-scroll-h .ui-trending .card-analytics-trending
{
    position: relative;
    top: 0.4rem;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.card-analytics .card-analytics-scroll-h .ui-trending .card-analytics-trending > div:nth-child(2)
{
    padding-left: calc(var(--ui-pad) * 2);
    padding-right: calc(var(--ui-pad) * 2);
}


.trending_equal, .trending_down, .trending_up
{
    height        : 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.trending_equal i::before
{
    content: 'linear_scale';
}

.trending_down i::before
{
    content: 'trending_down';
}

.trending_up i::before
{
    content: 'trending_up';
}


.trending_equal i::before
{
    color: rgb(0, 128, 255);
}


.trending_down i::before
{
    color: rgb(255, 24, 91);
}


.trending_up i::before
{
    color: rgb(128, 214, 0);
}

.trending_down i::before, .trending_up i::before
{
    /* font-weight: 800; */
}

.card-analytics-color *, .card-analytics-color .trending_down i::before, .card-analytics-color .trending_up i::before
{
    color: white;
}

.card-analytics-color * .ux-spinner-block
{
    background: transparent;
}


.card-analytics-color .light
{
    opacity: 0.966;
}


#analytics-overview > div:nth-child(1) .card-analytics-color
{
    background-color:  rgba(var(--ui-colors-md-800-blue), 1);
}


#analytics-overview > div:nth-child(2) .card-analytics-color
{
    background-color:rgba(var(--ui-colors-md-800-pink), 1);;
}


#analytics-overview > div:nth-child(3) .card-analytics-color
{
    background-color:rgba(var(--ui-colors-md-800-amber), 1);;
}


.cdk-overlay-container
{
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.cdk-overlay-container .modal-backdrop
{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--ui-colors-neutral-black), 0.56);
}


.cdk-overlay-container .cdk-global-overlay-wrapper
{
    position: relative;
    z-index: 1;
    width: auto;
    height: auto;
    min-width: 30rem;


    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.modal.active
{
    width: auto;
    height: auto;
    max-height: 100vh;

    overflow: hidden;
    overflow-y: auto;


    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
}


.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.modal.active .modal-card
{
    width: auto;
    height: auto;

    padding: calc(var(--ui-pad) * 6);

    background    : rgba(var(--ui-colors-neutral-white), 1);
    box-shadow    : var(--ui-shadow-card);
    border-radius: 16px;
}


.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.modal.active.page .modal-card
{
    border-radius: 0;
}
