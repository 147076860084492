
/********************************************************************
  SPLASH
 ********************************************************************/
#splash
{
    position   : fixed;
    z-index    : 10000;
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    overflow   : hidden;
    background : rgba(var(--ui-colors-neutral-white), 1);
}


#splash > figure
{
    position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


#splash > figure > img
{
   width         : 22rem;
   height        : auto;
   object-fit    : contain;
   border-radius : 100%;
   background    : rgba(var(--ui-colors-neutral-white), 1);
}


#splash > #splash-animation
{
    position      : absolute;
    z-index       : 10000;
    top           : 0;
    right         : 0;
    bottom        : 0;
    left          : 0;
    width         : 100%;
    height        : 100%;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


#splash > #splash-animation::before, #splash > #splash-animation::after
{
    content       : '';
    position      : absolute;
    z-index       : 0;
    width         : 26rem;
    height        : 26rem;
    border-width  : 0.4rem;
    border-radius : 100%;
    border-color  : transparent;
}


#splash > #splash-animation::before
{
    z-index          : 1;
    border-top-color : rgba(var(--ui-color-primary), 1);
    animation        : ux-spinner-block var(--ux-motion-spinner-ms) infinite linear;
}


/********************************************************************
  UI-HEADER
 ********************************************************************/
#ui-header
{
    position            : sticky;
    z-index             : 10;
    top                 : 0;
    left                : 0;
    width               : 100%;
    height              : var(--ui-header-height);
    overflow            : hidden;
    background-color    : rgba(var(--ui-colors-neutral-white), 0.870);
    border-bottom-width : 0.1rem;
    transition          : box-shadow var(--ux-motion-emphasized-ms) var(--ux-motion-emphasized-accelerate);
    box-shadow          : 0 0 1rem -0.1rem rgba(var(--ui-colors-neutral-black), 0);

    display             : flex;
    flex-direction      : row;
    flex-wrap           : nowrap;
    justify-content     : space-between;
    align-content       : center;
    align-items         : center;
}


.ux-is-scrolled #ui-header
{
    transition : box-shadow var(--ux-motion-emphasized-ms) var(--ux-motion-emphasized-decelerate);
    box-shadow : 0 0 1rem -0.1rem rgba(var(--ui-colors-neutral-black), 0.16);
}


#ui-header div
{
    width  : 100%;
    height : 100%;
}


#ui-header div:last-child
{
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-end;
    align-content   : center;
    align-items     : center;
}


#ui-header div figure
{
    float         : left;
    width         : calc(100% - var(--ui-btn-radius));
    height        : 100%;
}


#ui-header div figure a
{
    float           : left;
    width           : auto;
    height          : 100%;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}


#ui-header div figure a img
{
    float      : left;
    max-width  : 100%;
    max-height : 66%;
}


#ui-header .ui-btn
{
    float : left;
}


/********************************************************************
  BREADCRUMB
 ********************************************************************/
.ui-breadcrumb
{
    float   : left;
    width   : 100%;
    height  : auto;
    padding : 0 calc(var(--ui-pad) * 4);
}


.ui-breadcrumb ol
{
    float : left;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}


.ui-breadcrumb ol li
{
    float       : left;
    height      : var(--ui-header-height);

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}


.ui-breadcrumb:first-of-type ol li
{
    padding-top : calc(var(--ui-pad) * 2);
}

.ui-breadcrumb:first-of-type ~ section:first-of-type
{
    padding-top: 0;
}


.ui-breadcrumb ol li a, .ui-breadcrumb ol li span
{
    white-space     : nowrap;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


.ui-breadcrumb ol li a:hover
{
    text-decoration: underline;
}


.ui-breadcrumb ol li:first-child a, .ui-breadcrumb ol li + li a::before, .ui-breadcrumb ol li + li span::before
{
    font-family : "material-icons";
    position    : relative;
    color       : var(--ui-font-light-color);
    float       : left;
    padding     : 0 calc(var(--ui-pad) * 1.33);
    height      : 100%;
    opacity     : 0.89;
}


.ui-breadcrumb ol li + li a::before, .ui-breadcrumb ol li + li span::before
{
    content : "\e5e1";
    opacity : 0.66;
}


.ui-breadcrumb ol li:first-child a
{
    top       : -0.2rem;
    font-size : 1.9rem;
    padding   : 0 calc(var(--ui-pad) / 2) 0 calc(var(--ui-pad) / 2);
}


.ui-breadcrumb ol li:last-child a
{
    text-decoration: none;
}


.ui-breadcrumb ol li:first-child a:hover
{
    text-decoration: none;
    opacity: 1;
}


@media (max-width: 1023px)
{
    .ui-breadcrumb ol li:first-child a
    {
        top       : -0.1rem;
        font-size : 2.2rem;
        padding   : 0 0 calc(var(--ui-pad) / 2.33) calc(var(--ui-pad) / 2.33) ;
    }

    .ui-breadcrumb ol li + li a::before, .ui-breadcrumb ol li + li span::before
    {
        top : -0.1rem;
    }
}


/********************************************************************
  DRAWER
 ********************************************************************/

.ui-drawer
{
    position         : absolute;
    z-index          : 11;
    top              : 0;
    bottom           : 0;
    width            : 100%;
    height           : 100%;
    background-color : rgba(var(--ui-colors-neutral-white), 1);
    transition       : transform  var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate);
}


.ui-drawer .ui-drawer-navigation
{
    left       : 0;
    opacity    : 1;
    transform  : translate3d(0, 0, 0);
    transition : transform var(--ux-motion-standard-ms) var(--ux-motion-emphasized-decelerate),
                 opacity   var(--ux-motion-standard-ms) var(--ux-motion-emphasized-decelerate);
}

.ui-drawer .ui-drawer-nav-sub
{
    left       :  0;
    z-index    : -1;
    opacity    :  0;
    transform  : translate3d(0.6rem, 0, 0);
    transition : transform var(--ux-motion-standard-ms) var(--ux-motion-emphasized-decelerate),
                 opacity   var(--ux-motion-standard-ms) var(--ux-motion-emphasized-decelerate),
                 z-index   calc(var(--ux-motion-standard-ms) / 2) step-end;
}


#ui-drawer-nav .ui-drawer-nav-sub
{
    transform  : translate3d(-0.6rem, 0, 0);
}


.ui-drawer .ui-nav-subhead .ui-nav-subhead-icon i.mat-icon
{
    top: -0.1rem;
}


.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show,
.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show ~ .ui-drawer-navigation,
.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show ~ .ui-drawer-nav-sub-show
{
    transition : transform var(--ux-motion-standard-ms) var(--ux-motion-emphasized-decelerate),
                 opacity   var(--ux-motion-standard-ms) var(--ux-motion-emphasized-decelerate);
}

.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show,
#ui-drawer-nav .ui-drawer-nav-sub.ui-drawer-nav-sub-show
{
    z-index   : 1;
    opacity   : 1;
    transform : translate3d(0, 0, 0);
}

.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show ~ .ui-drawer-navigation,
.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show ~ .ui-drawer-nav-sub-show
{
    opacity   : 0;
}

.ui-drawer .ui-drawer-nav-sub .ui-drawer-nav-head .ui-btn
{
    pointer-events: none;
}

.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show .ui-drawer-nav-head .ui-btn
{
    pointer-events: initial;
}


.ui-drawer .ui-drawer-nav-sub.ui-drawer-nav-sub-show ~ .ui-drawer-nav-sub-show
{
    opacity : 1;
}


.ui-drawer .ellipsis
{
    width: 100%;
}


.ui-drawer-nav-head,
.ui-drawer-nav,
.ui-drawer-nav > .ui-scroll
{
    position : relative;
    float    : left;
    clear    : both;
    width    : 100%;
}

.ui-drawer-nav-head
{
    height              : var(--ui-header-height);
    border-bottom-width : 0.1rem;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}

.ui-drawer-nav-head > div
{
    float  : left;
    width  : calc(100% - var(--ui-btn-radius));
    height : 100%;
    padding-left : calc(var(--ui-pad) * 4);

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}


.ui-drawer-nav-head .ui-btn
{
    float : left;
}


.ui-drawer-nav-head .ui-btn + div
{
    padding-right : calc(var(--ui-pad) * 2);
    padding-left  : calc(var(--ui-pad) * 2);
}


.ui-drawer-nav
{
    height: calc(100% - var(--ui-header-height));
}


.ui-drawer-nav > .ui-scroll
{
    height : 100%;
}


.ui-drawer-nav .ui-scroll span + .ui-nav-subhead-icon
{
    padding-right : 0;
}


.ui-drawer-nav .ui-scroll .ui-nav-subhead .ui-nav-subhead-icon
{
    width         : auto;
    padding-right : calc(var(--ui-pad) * 2);
    padding-left  : calc(var(--ui-pad) / 2);
}


.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div hr
{
    padding: 0;
}


.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div
{
    position         : relative;
    top              : calc((var(--ui-header-height) / 2) * (-1));
    float            : left;
    width            : 100%;
    height           : var(--ui-btn-nav);
    background-color : initial;
    will-change      : background-color;
    transition       : background-color var(--ux-motion-emphasized-ms) var(--ux-motion-emphasized-decelerate);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div,
.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div > *,
.ui-drawer-nav-head, .ui-drawer-nav-head > *
{
    padding-top    : 0;
    padding-bottom : 0;
}


.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div.is-link-active
{
    background-color: var(--ui-link-active-bg-color);
}


.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div a
{
    float       : left;
    width       : 100%;
    height      : auto;
    text-shadow : none;
}


.ui-drawer-nav .cdk-virtual-scroll-content-wrapper div a.is-link-active
{
    text-shadow: 0 0 0.1rem var(--ui-link-active-color);
}


.ui-drawer-nav .ux-ripple
{
    cursor : pointer;
}


.ui-drawer *[data-drawer-nav-sub-toggle]
{
    justify-content : space-between;
}


.ui-drawer *[data-drawer-nav-sub-toggle] .mat-icon
{
    top       : 0.1rem;
    font-size : 2.6rem;
    color     : rgba(var(--ui-color-secondary), 1);
}


.ui-drawer .ui-drawer-nav-head *[data-drawer-nav-sub-toggle] .mat-icon
{
    top       : 0;
    font-size : 3.4rem;
    color     : rgba(var(--ui-color-failure), 1);
}


@media (max-width: 1023px)
{
    .ui-drawer *[data-drawer-nav-sub-toggle] .mat-icon
    {
        top       : 0;
        font-size : 3rem;
    }


    .ui-drawer .ui-drawer-nav-head *[data-drawer-nav-sub-toggle] .mat-icon
    {
        font-size : 4rem;
    }
}


#ui-drawer-nav, #ui-drawer-search, #ui-drawer-account, #ui-drawer-notifications, #ui-drawer-shopping
{
    will-change : transform;
    background : rgba(var(--ui-colors-neutral-white), 1);
    backface-visibility : hidden;
}


#ui-drawer-nav
{
    left               : calc(var(--ui-drawer-reverse) - var(--ui-drawer-transform));
    width              : 100%;
    max-width          : var(--ui-drawer);
    border-right-width : 0.1rem;
    transform          : translate3d(0, 0, 0);
}


#ui-drawer-search, #ui-drawer-account, #ui-drawer-notifications, #ui-drawer-shopping
{
    right             : calc(var(--ui-drawer-reverse) - var(--ui-drawer-transform));
    width             : 100%;
    max-width         : var(--ui-drawer);
    border-left-width : 0.1rem;
    transform         : translate3d(0, 0, 0);
}


@media (max-width: 1023px)
{
    #ui-drawer-nav
    {
        border-right-width : 0rem;
    }


    #ui-drawer-search, #ui-drawer-account, #ui-drawer-notifications, #ui-drawer-shopping
    {
        border-left-width : 0rem;
    }
}


#ui-btn-search i::before
{
    content   : 'manage_search';
    top       : -1px;
    font-size : 3.2rem;
}

/********************************************************************
  UI-SEARCH
 ********************************************************************/
#ui-filters
{
    height : 100%;
}


#ui-filters form
{
    height : calc(100% - var(--ui-header-height));
}


#ui-filters .form-wrapper, #ui-filters .spr-btn
{
    padding-left  : calc(var(--ui-pad) * 4);
    padding-right : calc(var(--ui-pad) * 4);
}


#ui-filters .spr-btn .form-wrapper
{
    padding-left  : 0;
    padding-right : 0;
}


#ui-filters .form-wrapper.form-wrapper-inline
{
    padding-left  : calc(var(--ui-pad) * 3);
    padding-right : calc(var(--ui-pad) * 3);
}


#ui-filters .form-wrapper.form-wrapper-inline > .form-content
{
    padding-left  : var(--ui-pad);
    padding-right : var(--ui-pad);
}


#ui-filters .ui-nav-subhead
{
    padding-left : calc(var(--ui-pad) * 4);
}


#ui-filters #ui-filter-search
{
    float               : left;
    width               : 100%;
    height              : var(--ui-header-height);
    padding-right       : var(--ui-pad);
    border-bottom-width : 0.1rem;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


#ui-filters #ui-filter-search .form-wrapper,
#ui-filters #ui-filter-search .form-wrapper .form-content
{
    height : 100%;
}


#ui-filters #ui-filter-search .form-wrapper
{
    width : calc(100% - var(--ui-btn-nav));
}


#ui-filters #ui-filter-search button
{
    width  : var(--ui-btn-nav);
    height : var(--ui-btn-nav);
}


#ui-filters #ui-filter-search .form-content .form-el
{
    border-color : transparent;
}


#ui-filters #ui-filter-options
{
    float  : left;
    width  : 100%;
    height : calc(100% - var(--ui-header-height));
    padding-top    : calc(var(--ui-btn-nav) / 2);
    padding-bottom : var(--ui-btn-nav);
}


#ui-filters .ui-scroll .ui-nav-subhead,
#ui-filters .ui-scroll .ui-nav-subhead-hr
{
    float           : left;
    width           : 100%;
    height          : var(--ui-btn-nav);

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}


#ui-filters .ui-scroll .ui-nav-subhead
{
    padding-top : 0;
}


#ui-filters .ui-scroll .ui-nav-subhead .ui-nav-subhead-icon
{
    width         : auto;
    padding-right : calc(var(--ui-pad) * 2);
    padding-left  : calc(var(--ui-pad) / 2);
}


#ui-filters .ui-scroll .ui-nav-subhead-hr
{
    padding-left  : 0;
    padding-right : 0;
    opacity       : 0.54;
}


#ui-filters ux-spinners.spr-btn
{
    padding-left  : 0;
    padding-right : 0;
}

#ui-filters #ui-filter-action
{
    float  : left;
    width  : 100%;
    height : var(--ui-header-height);
    border-top-width : 0.1rem;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


#ui-filters #ui-filter-action .form-wrapper
{
    padding : 0;
}


#ui-filters #ui-filter-action .form-wrapper,
#ui-filters #ui-filter-action .form-wrapper .form-content,
#ui-filters #ui-filter-action .form-wrapper .form-content label,
#ui-filters #ui-filter-action .form-wrapper .form-content label > *
{
    height        : var(--ui-header-height);
    border-radius : 0;
}


#ui-filters #ui-filter-action .form-wrapper .form-content label
{
    padding-left  : calc(var(--ui-pad) * 3);
    padding-right : calc(var(--ui-pad) * 6);
}


/********************************************************************
  UI-BACKDROP
 ********************************************************************/

#ui-backdrop
{
    position    : absolute;
    z-index     : -10;
    top         : 0;
    left        : 0;
    width       : 100%;
    height      : 100%;
    overflow    : hidden;
    background  : var(--ui-backdrop-color);
    opacity     : 0;
    will-change : opacity;
    transition  : opacity var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate),
                  z-index var(--ux-motion-emphasized-accelerate-ms) step-end;
}


#ui-backdrop.ui-drawer-nav,
#ui-backdrop.ui-drawer-shopping,
#ui-backdrop.ui-drawer-notifications,
#ui-backdrop.ui-drawer-account
{
    opacity    : 1;
    z-index    : 10;
    transition : opacity var(--ux-motion-emphasized-decelerate-ms) var(--ux-motion-emphasized-decelerate);
}


@media (max-width: 1023px)
{
    #ui-backdrop.ui-drawer-search
    {
        opacity    : 1;
        z-index    : 10;
        transition : opacity var(--ux-motion-emphasized-decelerate-ms) var(--ux-motion-emphasized-decelerate);
    }
}


#ui-backdrop ~ #ui-drawer-nav,
#ui-backdrop ~ #ui-drawer-shopping,
#ui-backdrop ~ #ui-drawer-notifications,
#ui-backdrop ~ #ui-drawer-account,
#ui-backdrop ~ #ui-drawer-search
{
    transition : transform var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate),
                 opacity   var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate);
}


#ui-backdrop.ui-drawer-nav ~ #ui-drawer-nav,
#ui-backdrop.ui-drawer-shopping ~ #ui-drawer-shopping,
#ui-backdrop.ui-drawer-notifications ~ #ui-drawer-notifications,
#ui-backdrop.ui-drawer-account ~ #ui-drawer-account,
#ui-backdrop.ui-drawer-search ~ #ui-drawer-search
{
    transition :  transform var(--ux-motion-emphasized-decelerate-ms) var(--ux-motion-emphasized-decelerate);
}


#ui-backdrop.ui-drawer-nav ~ #ui-drawer-nav
{
    transform : translate3d(calc(100% + var(--ui-drawer-transform)), 0, 0);
}

#ui-backdrop.ui-drawer-shopping ~ #ui-drawer-shopping,
#ui-backdrop.ui-drawer-notifications ~ #ui-drawer-notifications,
#ui-backdrop.ui-drawer-account ~ #ui-drawer-account,
#ui-backdrop.ui-drawer-search ~ #ui-drawer-search
{
    transform : translate3d(calc(-100% - var(--ui-drawer-transform)), 0, 0);
}


#ui-backdrop ~ #ui-page > .ui-section
{
    will-change : width, scroll-position, contents;
    width       : 100%;
}


@media (min-width: 1024px)
{
    #ui-backdrop ~ #ui-drawer-search
    {
        z-index : 9;
    }

    #ui-backdrop ~ #ui-page > .ui-section
    {
        transition  : width var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate);
    }

    #ui-backdrop.ui-drawer-search ~ #ui-page > .ui-section
    {
        width      : calc(100% - var(--ui-drawer));
        transition : width var(--ux-motion-emphasized-decelerate-ms) var(--ux-motion-emphasized-decelerate);
    }

    #ui-backdrop.ui-drawer-search ~ * #ui-btn-search i::before
    {
        content   : 'chevron_right';
        top       : 0;
        font-size : 3.8rem;
    }
}


/********************************************************************
  SECTION
 ********************************************************************/
.ui-section
{
    position    : absolute;
    z-index     : 0;
    top         : 0;
    right       : 0;
    bottom      : 0;
    left        : 0;
    width       : 100%;
    height      : 100%;
    background  : rgba(var(--ui-colors-neutral-white), 1);
}


.ui-section-fixed-content
{
    position   : fixed;
    z-index    : 11;
    top        : 0;
    left       : 0;
    bottom     : 0;
    width      : 100%;
    height     : 100%;
    overflow   : hidden;
    overflow-y : auto;
    background  : rgba(var(--ui-colors-neutral-white), 1);
}


.ui-section-grey, .ui-section-fixed-content-grey
{
    background-color: rgba(var(--ui-color-bg-grey), 1);
}
