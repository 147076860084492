/********************************************************************
 RESET
********************************************************************/
article, aside, details, figcaption, figure, footer, header, hgroup,
menu, nav, section
{
    display : block;
}


*, *::before, *::after
{
    margin              : 0;
    padding             : 0;
    border              : 0;
    outline             : 0;
    box-sizing          : border-box;
    border-style        : solid;
    font-family         : "roboto-flex", "roboto", sans-serif;
    font-stretch        : 100%;
    font-optical-sizing : auto;
    border-color        : var(--ui-border-color);
    color               : var(--ui-font-color);
    scrollbar-width     : thin;
    scrollbar-color     : #bdbdbd #e5e5e5;

    -webkit-tap-highlight-color : rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color : transparent;
    -webkit-touch-callout       : none;
}


html, body, app-root, #ui-page
{
    position       : absolute;
    z-index        : 0;
    top            : 0;
    right          : 0;
    bottom         : 0;
    left           : 0;
    width          : 100%;
    height         : 100%;
    overflow       : hidden;
    font           : inherit;
    vertical-align : baseline;
}


html
{
    font-size       : 0.6250rem;
    line-height     : 0.6250rem;
    letter-spacing  : 0.0062rem;
    word-spacing    : 0.0062rem;

    -webkit-text-size-adjust : 100%;
       -moz-text-size-adjust : 100%;
        -ms-text-size-adjust : 100%;
         -o-text-size-adjust : 100%;
            text-size-adjust : 100%;

    -ms-touch-action : manipulation;
        touch-action : manipulation;
}


body
{
    text-rendering : optimizeLegibility;

    -webkit-font-smoothing : antialiased;
            font-smoothing : antialiased;

    -webkit-user-drag : none;

    -ms-content-zooming : none;

    -webkit-text-size-adjust : none;
       -moz-text-size-adjust : none;
        -ms-text-size-adjust : none;
         -o-text-size-adjust : none;
            text-size-adjust : none;

     -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing : grayscale;
}


@media (max-width: 1023px)
{
    body
    {
    -webkit-user-select : none;
       -moz-user-select : none;
        -ms-user-select : none;
         -o-user-select : none;
            user-select : none;
    }
}


body, body *
{
    font-size      : 1.4000rem;
    line-height    : 161.8%;
    font-weight    : 400;
    letter-spacing : 0.01618rem;
    word-spacing   : 0.01618rem;
}


@media (max-width: 1023px)
{
    body, body *
    {
        font-size      :  1.900000rem;
        letter-spacing : -0.024618rem;
        word-spacing   : -0.024618rem;
    }
}


/********************************************************************
 FORMS
********************************************************************/
::-webkit-input-placeholder
{
    color          : rgba(var(--ui-color-light), 0.399);
    opacity        : 1;
    text-transform : capitalize;
}


:-moz-placeholder
{
    color          : rgba(var(--ui-color-light), 0.399);
    opacity        : 1;
    text-transform : capitalize;
}


::-moz-placeholder
{
    color          : rgba(var(--ui-color-light), 0.399);
    opacity        : 1;
    text-transform : capitalize;
}


:-ms-input-placeholder
{
    color          : rgba(var(--ui-color-light), 0.399);
    opacity        : 1;
    text-transform : capitalize;
}


::-ms-input-placeholder
{
    color          : rgba(var(--ui-color-light), 0.399);
    opacity        : 1;
    text-transform : capitalize;
}


::placeholder
{
    color          : rgba(var(--ui-color-light), 0.399);
    opacity        : 1;
    text-transform : capitalize;
}


input[type="date"]::-webkit-inner-spin-button
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="date"]::-webkit-outer-spin-button
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="date"]::-webkit-clear-button
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-webkit-slider-runnable-track
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-webkit-slider-thumb
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]:focus::-webkit-slider-runnable-track
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-webkit-slider-thumb
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]:focus::-webkit-slider-runnable-track
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-moz-range-track
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-moz-range-thumb
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-ms-track
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-ms-fill-lower
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-ms-fill-upper
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]::-ms-thumb
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]:focus::-ms-fill-lower
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="range"]:focus::-ms-fill-upper
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button,
input[type="date"]::-ms-clear
{
    display : none;
}


input[type="date"]::-webkit-calendar-picker-indicator
{
    position   : absolute;
    top        : 0;
    right      : 0;
    width      : var(--font-size-icon);
    height     : 100%;
    color      : transparent;
    background : transparent;
    cursor     : pointer;
}


input[type="date"]::-webkit-calendar-picker-indicator:hover
{
    background : transparent;
}


input[type="date"]
{
    position : relative;
    cursor   : pointer;
}


input[type="range"]::-ms-fill-lower
{
    color            : transparent;
    background-color : transparent;
}


input[type="range"]::-ms-fill-upper
{
    color            : transparent;
    background-color : transparent;
}


input[type="range"]:focus::-ms-fill-lower
{
    color            : transparent;
    background-color : transparent;
}


input[type="range"]:focus::-ms-fill-upper
{
    color            : transparent;
    background-color : transparent;
}


input[type="range"]::-webkit-slider-runnable-track
{
    width                       : 100%;
    height                      : 1px;
    margin                      : 0;
    background                  : rgba(30, 34, 39, 0.166);
    border                      : none;
    border-radius               : 0;
    cursor                      : pointer;
    -webkit-tap-highlight-color : transparent;
    -webkit-box-shadow          : inset 0 0 0 0 transparent;
            box-shadow          : inset 0 0 0 0 transparent;
}


input[type="range"]::-moz-range-track
{
    width                       : 100%;
    height                      : 1px;
    margin                      : 0;
    background                  : rgba(30, 34, 39, 0.166);
    border                      : none;
    border-radius               : 0;
    cursor                      : pointer;
    -webkit-tap-highlight-color : transparent;
    -webkit-box-shadow          : inset 0 0 0 0 transparent;
            box-shadow          : inset 0 0 0 0 transparent;
}


input[type="range"]::-ms-track
{
    width                       : 100%;
    height                      : 1px;
    margin                      : 0;
    background                  : rgba(30, 34, 39, 0.166);
    border                      : none;
    border-radius               : 0;
    cursor                      : pointer;
    -webkit-tap-highlight-color : transparent;
    -webkit-box-shadow          : inset 0 0 0 0 transparent;
            box-shadow          : inset 0 0 0 0 transparent;
}


input[type="range"]::-webkit-slider-thumb
{
    width                       : 22px;
    height                      : 11px;
    background                  : rgba(100, 101, 102, 1);
    margin                      : -0.5rem 0 0 0;
    border-radius               : 2px;
    border                      : none;
    cursor                      : pointer;
    -webkit-tap-highlight-color : transparent;
    -webkit-box-shadow          : inset 0 0 0 0 transparent;
            box-shadow          : inset 0 0 0 0 transparent;
}


input[type="range"]::-moz-range-thumb
{
    width                       : 22px;
    height                      : 11px;
    background                  : rgba(100, 101, 102, 1);
    margin                      : 0 0 0 0;
    border-radius               : 2px;
    border                      : none;
    cursor                      : pointer;
    -webkit-tap-highlight-color : transparent;
    -webkit-box-shadow          : inset 0 0 0 0 transparent;
            box-shadow          : inset 0 0 0 0 transparent;
}


input[type="range"]::-ms-thumb
{
    width                       : 15px;
    height                      : 7px;
    background                  : rgba(100, 101, 102, 1);
    margin                      : 0 0 0 0;
    border-radius               : 1px;
    border                      : none;
    cursor                      : pointer;
    -webkit-tap-highlight-color : transparent;
    -webkit-box-shadow          : inset 0 0 0 0 transparent;
            box-shadow          : inset 0 0 0 0 transparent;
}


input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,
textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus,
select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus
{
    border-width            : 1px;
    border-top-color        : transparent;
    border-left-color       : transparent;
    border-right-color      : transparent;
    transition              : background-color 5000s ease-in-out 0s;
    -webkit-box-shadow      : 0 0 0 1000px transparent inset;
            box-shadow      : 0 0 0 1000px transparent inset;
    -webkit-text-fill-color : var(--ui-font-color);
}


.form-wrapper
{
    float   : left;
    width   : 100%;
}


.form-wrapper.form-wrapper-inline
{
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : space-between;
    align-content   : center;
    align-items     : center;
}


.form-wrapper .form-content
{
    float           : left;
    width           : 100%;
    height          : 8rem;

    display         : flex;
    flex-direction  : column;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


.form-wrapper.form-wrapper-button .form-content
{
    height : auto;
}


.form-wrapper .form-content label, .form-wrapper .form-content.form-content-check
{
    position : relative;
    width    : 100%;
    height   : var(--ui-form-el);
}


.form-wrapper .form-content.form-content-button.form-content-button-big label
{
    height : var(--ui-btn-radius);
}


.form-wrapper .form-content.form-content-button label
{
    overflow         : hidden;
    border-radius    : var(--ui-border-radius);
    background-color : rgba(var(--ui-color-primary), 1);
}


.form-wrapper .form-content.form-content-button label *
{
    color : rgba(var(--ui-colors-neutral-white), 0.900);
}


.form-wrapper .form-content.form-content-button.transparent label
{
    background-color : transparent;
}


.form-wrapper .form-content.form-content-button.transparent label *
{
    color : var(--ui-font-color);
}


.form-wrapper .form-content.form-content-button label > button
{
    text-align     : center;
    text-transform : uppercase;
    overflow       : hidden;
}

.form-wrapper .form-content.form-content-button label > button span.center
{
    width      : 100%;
    text-align : center;
}


.form-wrapper .form-content label .form-el
{
    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;

    position           : absolute;
    z-index            : 1;
    top                : 0;
    left               : 0;
    width              : 100%;
    height             : 100%;
    cursor             : pointer;
    border-width       : 0rem;
    border-color       : transparent;

    box-shadow       : none;
    background       : transparent;
    background-color : transparent;
    border-radius    : 0;
    padding          : 0;
    text-indent      : 0;

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : flex-start;
    align-content   : center;
    align-items     : center;
}


@-moz-document url-prefix()
{
    .form-wrapper .form-content label select.form-el
    {
        text-indent : -0.2rem;
    }
}


@supports (-ms-ime-align: auto)
{
    .form-wrapper .form-content label select.form-el
    {
        width       : calc(100% + 0.4rem);
        margin-left : -0.4rem;
    }
}


.form-wrapper .form-content label .form-el ~ hr
{
    will-change      : height, background-color;
    position         : absolute;
    z-index          : 1;
    bottom           : 0;
    left             : 0;
    width            : 100%;
    height           : 0.1rem;
    background-color : var(--ui-border-color-form);
    border-width     : 0;
    transition       : height           var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate),
                       background-color var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate);
}


.form-wrapper .form-content label .form-el:focus ~ hr
{
    height           : 0.2rem;
    background-color : var(--ui-form-color);
}


.form-wrapper.form-wrapper-textarea
{
    width     : 100%;
    max-width : none;
    padding   : 2.6rem var(--ui-pad);
}


.form-wrapper.form-wrapper-textarea .form-content,
.form-wrapper.form-wrapper-textarea .form-content label
{
    height : auto;
}


.form-wrapper.form-wrapper-textarea .form-content label .form-icon-left
{
    position : absolute;
    top      : 0;
    left     : -0.6rem;
}


.form-wrapper.form-wrapper-textarea .form-content label .form-el
{
    position       : relative;
    float          : left;
    display        : block;
    width          : 100%;
    height         : 100%;
    min-height     : 12.4rem;
    padding-top    : 0.6rem;
    padding-bottom : calc(var(--ui-pad) * 3);
    resize         : none;
    overflow       : hidden;
}


.form-wrapper .form-content.form-content-check label .form-el,
.form-wrapper .form-content.form-content-button label .form-el
{
    border-color : transparent;
}


.form-wrapper .form-content label .form-title,
.form-wrapper .form-content label .form-help
{
    position   : absolute;
    z-index    : 0;
    left       : 0;
    width      : 100%;
    transition : all var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate);
}

.form-wrapper .form-content label .form-title
{
    bottom : calc(100% - 0.200rem);
}

.form-wrapper .form-content label .form-help
{
    top : calc(100% - 0.100rem);
}

.form-wrapper .form-content label .form-el:focus ~ .form-title
{
    opacity : 1;
    color   : var(--ui-form-color);
}


.form-wrapper .form-content label .form-help .form-helper,
.form-wrapper .form-content label .form-help .form-required
{
    position   : absolute;
    top        : 0.1rem;
    left       : 0;
    width      : 100%;
    color      : var(--ui-font-light-color);
    transition : all var(--ux-motion-emphasized-accelerate-ms) var(--ux-motion-emphasized-accelerate);
}


.form-wrapper .form-content label .form-help .form-required
{
    color : rgba(var(--ui-color-failure), 0.599);
}

.form-wrapper .form-content label .form-help .form-required ~ .form-helper
{
    opacity   : 0;
    transform : translate3d(0, 75%, 0);
}


.form-wrapper .form-content label .form-el:focus ~ .form-help .form-required ~ .form-helper
{
    color     : var(--ui-font-light-color);
    transform : translate3d(0, 0, 0);
}


.form-wrapper .form-content label .form-el:focus ~ .form-help .form-required
{
    opacity   : 0;
    transform : translate3d(0, -75%, 0);
}


.form-wrapper .form-content label .form-icon-left,  .form-wrapper .form-content label .form-icon-right
{
    position : relative;
    top      : 0;
    width    : 3.6rem;
    height   : var(--ui-form-el);

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


.form-wrapper .form-content label .form-icon-left
    {
    float : left;
    left  : -0.6rem;
}

.form-wrapper .form-content label .form-icon-right
{
   float : right;
   right : -0.6rem;
}

.form-wrapper .form-content.form-content-button label .form-icon-left
{
   left : 0;
}

.form-wrapper .form-content.form-content-button label .form-icon-right
{
   right : 0;
}

.form-wrapper .form-content.form-content-button label .form-icon-left,
.form-wrapper .form-content.form-content-button label .form-icon-right
{
    height : 100%;
}

.form-wrapper .form-content label .form-icon-left i,
.form-wrapper .form-content label .form-icon-right i
{
    width           : 100%;
    height          : 100%;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


.form-wrapper .form-content label .form-icon-left > .ux-ripple,
.form-wrapper .form-content label .form-icon-right > .ux-ripple
{
    position      : absolute;
    top           : 50%;
    left          : 50%;
    transform     : translate(-50%, -50%);
    width         : 4rem;
    height        : 4rem;
    border-radius : 100%;
    overflow      : hidden;
}


.form-wrapper .form-content label .form-icon-left ~ .form-el
{
    padding-left : 3.2rem;
}

.form-wrapper .form-content label .form-icon-right ~ .form-el
{
    padding-right : 3.2rem;
}


.form-wrapper .form-content.form-content-button label .form-icon-left ~ .form-el
{
    padding-left : 3.6rem;
}

.form-wrapper .form-content.form-content-button label .form-icon-right ~ .form-el
{
    padding-right : 3.6rem;
}


.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-left .mat-icon::before,
.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-left .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-left .mat-icon::before,
.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-left .mat-icon::after,

.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-right .mat-icon::before,
.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-right .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-right .mat-icon::before,
.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-right .mat-icon::after
{
    position   : absolute;
    z-index    : 0;
    transition : all var(--ux-fast-animations) ease-out;
}

.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-left .mat-icon::before,
.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-right .mat-icon::before
{
    content : 'check_box_outline_blank';
}


.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-left .mat-icon::before,
.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-right .mat-icon::before
{
    content : 'radio_button_unchecked';
}


.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-left .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="checkbox"] ~ .form-icon-right .mat-icon::after
{
    content   : 'check_box';
    transform : scale3d(0, 0, 1);
}

.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-left .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="radio"] ~ .form-icon-right .mat-icon::after
{
    content   : 'radio_button_checked';
    transform : scale3d(0, 0, 1);
}

.form-wrapper .form-content.form-content-check label input[type="checkbox"]:checked ~ .form-icon-left .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="radio"]:checked ~ .form-icon-left .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="checkbox"]:checked ~ .form-icon-right .mat-icon::after,
.form-wrapper .form-content.form-content-check label input[type="radio"]:checked ~ .form-icon-right .mat-icon::after
{
    transform : scale3d(1, 1, 1);
    color     : var(--ui-form-color);
    z-index   : 1;
}


/********************************************************************
 SCROLL
********************************************************************/
@media (min-width: 1024px)
{
    ::-webkit-scrollbar-track
    {
        background-color : #e5e5e5;
        width            : 4px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color : #bdbdbd;
        border           : 1px solid transparent;
        background-clip  : content-box;
    }

    ::-webkit-scrollbar-thumb:vertical:hover
    {
        background-color : #999999;
    }

    ::-webkit-scrollbar
    {
        width  : 8px;
        height : 8px;
    }
}

/********************************************************************
 @KEYFRAMES
********************************************************************/

@keyframes ux-ripple
{
 0%     { opacity : 0.165; transform : translate3d(0, 0, 0) scale3d(0, 0, 1); }
 33.33% { opacity : 0.333; transform : translate3d(0, 0, 0) scale3d(4, 4, 1); }
 100%   { opacity : 0.000; transform : translate3d(0, 0, 0) scale3d(4, 4, 1); }
}


@keyframes ux-spinner-block
{
 0%   { transform: rotate(0deg);   }
 100% { transform: rotate(360deg); }
}


/********************************************************************
 NORMALIZE
********************************************************************/


.mat-icon, .mat-icon::before, .mat-icon::after
{
    position        : relative;
    font-family     : 'material-icons';
    font-size       : 2.4rem;
    line-height     : 100%;
    font-weight     : normal;
    font-style      : normal;
    color           : var(--ui-icon-color);
    text-transform  : none;
    letter-spacing  : normal;
    word-wrap       : normal;
    white-space     : nowrap;
    direction       : ltr;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


.fs-xxx-big   { font-size: 4.225em; letter-spacing: -0.02666em; word-spacing: -0.02666em; line-height: 116.5%; font-weight: 300;}
.fs-xx-big    { font-size: 3.900em; letter-spacing: -0.02666em; word-spacing: -0.02666em; line-height: 116.5%; font-weight: 300;}
.fs-x-big     { font-size: 3.500em; letter-spacing: -0.02666em; word-spacing: -0.02666em; line-height: 121.8%; font-weight: 300;}
.fs-big       { font-size: 3.225em; letter-spacing: -0.02666em; word-spacing: -0.02666em; line-height: 126.5%; font-weight: 300;}
.fs-xxx-large { font-size: 2.900em; letter-spacing: -0.02666em; word-spacing: -0.02666em; line-height: 131.8%; font-weight: 300;}
.fs-xx-large  { font-size: 2.225em; letter-spacing: -0.02225em; word-spacing: -0.02225em; line-height: 139.3%; font-weight: 300;}
.fs-x-large   { font-size: 1.760em; letter-spacing: -0.01760em; word-spacing: -0.01760em; line-height: 146.8%; font-weight: 300;}
.fs-large     { font-size: 1.500em; letter-spacing: -0.01500em; word-spacing: -0.01500em; line-height: 154.3%; font-weight: 300;}
.fs-medium    { font-size: 1.155em; letter-spacing:  0.01155em; word-spacing:  0.01155em; line-height: 151.8%; }
.fs-small     { font-size: 0.800em; letter-spacing:  0.03610em; word-spacing:  0.03610em;   }
.fs-x-small   { font-size: 0.700em; letter-spacing:  0.05760em; word-spacing:  0.05760em;   }


.fs-condensed
{
    word-spacing: -0.1618em;
}


.f-left
{
    text-align : left;
}


.f-center
{
    text-align : center;
}

.f-right
{
    text-align : right;
}


[type="checkbox"], [type="radio"], [type="file"]
{
    display : none;
}


a:link, a:active, a:visited, a:hover
{
    text-decoration : none;
}


ol, ul, li
{
    list-style          : none;
    list-style-position : inside;
}


table
{
    border-collapse : collapse;
    border-spacing  : 0;
}


hr
{
    width               : 100%;
    border-bottom-width : 0.1rem;
}


.bold, strong, b
{
    font-weight : var(--ui-font-bold);
    color: var(--ui-font-bold-color);
}


.light
{
    color: var(--ui-font-light-color);
    opacity: 0.833;
}


.ellipsis
{
    position      : relative;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
    min-width     : 0;
}


.card
{
    background    : rgba(var(--ui-colors-neutral-white), 1);
    box-shadow    : var(--ui-shadow-card);
    border-radius : 3px;
}


.ui-pad, .ui-pad-x2, .ui-pad-x3, .ui-pad-x4, .ui-pad-x5,
.ui-pad-x6, .ui-pad-x7 .ui-pad-x8, .ui-pad-x9, .ui-pad-x10
{
    display  : block;
    position : relative;
    padding  : var(--ui-pad);
}


.ui-pad-x2  { padding  : calc(var(--ui-pad) *  2); }
.ui-pad-x3  { padding  : calc(var(--ui-pad) *  3); }
.ui-pad-x4  { padding  : calc(var(--ui-pad) *  4); }
.ui-pad-x5  { padding  : calc(var(--ui-pad) *  5); }
.ui-pad-x6  { padding  : calc(var(--ui-pad) *  6); }
.ui-pad-x7  { padding  : calc(var(--ui-pad) *  7); }
.ui-pad-x8  { padding  : calc(var(--ui-pad) *  8); }
.ui-pad-x9  { padding  : calc(var(--ui-pad) *  9); }
.ui-pad-x10 { padding  : calc(var(--ui-pad) * 10); }


.ui-scroll, .ui-scroll-h, .ui-section
{
    overflow            : hidden;
    overflow-y          : auto;
    scroll-behavior     : smooth;
    overscroll-behavior : none;
    will-change         : scroll-position;

    -webkit-overflow-scrolling : touch;
       -moz-overflow-scrolling : touch;
        -ms-overflow-scrolling : touch;
         -o-overflow-scrolling : touch;
            overflow-scrolling : touch;
}


.ui-scroll-h
{
    overflow   : hidden;
    overflow-x : auto;
}


.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper
{
    width  : 100%;
    height : auto;
}


.ui-btn
{
    width              : var(--ui-btn-radius);
    height             : var(--ui-btn-radius);
    overflow           : hidden;
    background-color   : transparent;
    padding            : 0;
    border-radius      : 100%;
    cursor             : pointer;

    -webkit-appearance : none;
       -moz-appearance : none;
        -ms-appearance : none;
         -o-appearance : none;
            appearance : none;
}


.ui-block, .ui-block > *
{
    position : relative;
    top      : 0;
    right    : 0;
    bottom   : 0;
    left     : 0;
    float    : left;
    width    : 100%;
    height   : auto;
}


ux-spinners
{
    display : block;
    width   : 100%;
    height  : 0;
}


.ux-spinner-block
{
    position      : absolute;
    z-index       : 10000;
    top           : 0;
    right         : 0;
    bottom        : 0;
    left          : 0;
    width         : 100%;
    height        : 100%;
    background    : var(--ui-backdrop-spinner);

    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : center;
    align-content   : center;
    align-items     : center;
}


.ux-spinner-block::before, .ux-spinner-block::after,
.spr-btn .ux-spinner-block::before, .spr-btn .ux-spinner-block::after
{
    content       : '';
    position      : absolute;
    z-index       : 0;
    height        : 56%;
    max-height    : 8.6rem;
    aspect-ratio  : 1;
    border-width  : 0.4rem;
    border-radius : 100%;
    border-color  : var(--ui-border-color);
}


.ux-spinner-block::before, .spr-btn .ux-spinner-block::before
{
    z-index          : 1;
    border-color     : transparent;
    border-top-color : rgba(var(--ui-color-primary), 1);
}


.ux-el-in-viewport .ux-spinner-block::before
{
    animation : ux-spinner-block var(--ux-motion-spinner-ms) infinite linear;
}


/********************************************************************
 UX-RIPPLE
********************************************************************/
.ux-ripple-wrapper
{
    position : absolute;
    z-index  : 1;
    top      : 0;
    right    : 0;
    bottom   : 0;
    left     : 0;
    width    : 100%;
    height   : 100%;
    display  : block;
    overflow : hidden;
}


.ux-ripple-wrapper .ux-ripple-effect
{
    position       : absolute;
    z-index        : 1;
    top            : 0;
    right          : 0;
    bottom         : 0;
    left           : 0;
    width          : 0;
    height         : 0;
    opacity        : 0;
    display        : block;
    border-radius  : 50%;
    cursor         : pointer;
    transform      : translate3d(0, 0, 0) scale3d(0, 0, 1);

    -webkit-backface-visibility : hidden;
       -moz-backface-visibility : hidden;
        -ms-backface-visibility : hidden;
         -o-backface-visibility : hidden;
            backface-visibility : hidden;

    -webkit-perspective : 1000;
       -moz-perspective : 1000;
        -ms-perspective : 1000;
         -o-perspective : 1000;
            perspective : 1000;
}


/********************************************************************
 UX-SHOW
********************************************************************/

.ux-show
{
    position    : relative;
    top         : 0;
    right       : 0;
    bottom      : 0;
    left        : 0;
    opacity     : 0;
    transform   : translate(0, var(--ux-show));
    transition  : transform var(--ux-motion-emphasized-ms) var(--ux-motion-emphasized-decelerate),
                  opacity   var(--ux-motion-emphasized-ms) var(--ux-motion-emphasized-decelerate);
}


.ux-show-bottom       { transform : translate(0,                      var(--ux-show)); }
.ux-show-bottom-left  { transform : translate(var(--ux-show-reverse), var(--ux-show)); }
.ux-show-bottom-right { transform : translate(var(--ux-show),         var(--ux-show)); }

.ux-show-left         { transform : translate(var(--ux-show-reverse), 0); }
.ux-show-right        { transform : translate(var(--ux-show),         0); }

.ux-show-top-left     { transform : translate(var(--ux-show-reverse), var(--ux-show-reverse)); }
.ux-show-top-right    { transform : translate(var(--ux-show),         var(--ux-show-reverse)); }
.ux-show-top          { transform : translate(0,                      var(--ux-show-reverse)); }


.ux-el-is-visible.ux-show
{
    opacity   : 1;
    transform : translate(0, 0);
}
