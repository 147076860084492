/********************************************************************
  ROOT
 ********************************************************************/

:root
{
    --ui-colors-md-50-red   : 255, 235, 238;
    --ui-colors-md-100-red  : 255, 205, 210;
    --ui-colors-md-200-red  : 239, 154, 154;
    --ui-colors-md-300-red  : 229, 115, 115;
    --ui-colors-md-400-red  : 239,  83,  80;
    --ui-colors-md-500-red  : 244,  67,  54;
    --ui-colors-md-600-red  : 229,  57,  53;
    --ui-colors-md-700-red  : 211,  47,  47;
    --ui-colors-md-800-red  : 198,  40,  40;
    --ui-colors-md-900-red  : 183,  28,  28;
    --ui-colors-md-a100-red : 255, 138, 128;
    --ui-colors-md-a200-red : 255,  82,  82;
    --ui-colors-md-a400-red : 255,  23,  68;
    --ui-colors-md-a700-red : 213,   0,   0;

    --ui-colors-md-50-pink   : 252, 228, 236;
    --ui-colors-md-100-pink  : 248, 187, 208;
    --ui-colors-md-200-pink  : 244, 143, 177;
    --ui-colors-md-300-pink  : 240,  98, 146;
    --ui-colors-md-400-pink  : 236,  64, 122;
    --ui-colors-md-500-pink  : 233,  30,  99;
    --ui-colors-md-600-pink  : 216,  27,  96;
    --ui-colors-md-700-pink  : 194,  24,  91;
    --ui-colors-md-800-pink  : 173,  20,  87;
    --ui-colors-md-900-pink  : 136,  14,  79;
    --ui-colors-md-a100-pink : 255, 128, 171;
    --ui-colors-md-a200-pink : 255,  64, 129;
    --ui-colors-md-a400-pink : 245,   0,  87;
    --ui-colors-md-a700-pink : 197,  17,  98;

    --ui-colors-md-50-purple   : 243, 229, 245;
    --ui-colors-md-100-purple  : 225, 190, 231;
    --ui-colors-md-200-purple  : 206, 147, 216;
    --ui-colors-md-300-purple  : 186, 104, 200;
    --ui-colors-md-400-purple  : 171,  71, 188;
    --ui-colors-md-500-purple  : 156,  39, 176;
    --ui-colors-md-600-purple  : 142,  36, 170;
    --ui-colors-md-700-purple  : 123,  31, 162;
    --ui-colors-md-800-purple  : 106,  27, 154;
    --ui-colors-md-900-purple  :  74,  20, 140;
    --ui-colors-md-a100-purple : 234, 128, 252;
    --ui-colors-md-a200-purple : 224,  64, 251;
    --ui-colors-md-a400-purple : 213,   0, 249;
    --ui-colors-md-a700-purple : 170,   0, 255;

    --ui-colors-md-50-deeppurple   : 237, 231, 246;
    --ui-colors-md-100-deeppurple  : 209, 196, 233;
    --ui-colors-md-200-deeppurple  : 179, 157, 219;
    --ui-colors-md-300-deeppurple  : 149, 117, 205;
    --ui-colors-md-400-deeppurple  : 126,  87, 194;
    --ui-colors-md-500-deeppurple  : 103,  58, 183;
    --ui-colors-md-600-deeppurple  :  94,  53, 177;
    --ui-colors-md-700-deeppurple  :  81,  45, 168;
    --ui-colors-md-800-deeppurple  :  69,  39, 160;
    --ui-colors-md-900-deeppurple  :  49,  27, 146;
    --ui-colors-md-a100-deeppurple : 179, 136, 255;
    --ui-colors-md-a200-deeppurple : 124,  77, 255;
    --ui-colors-md-a400-deeppurple : 101,  31, 255;
    --ui-colors-md-a700-deeppurple :  98,   0, 234;

    --ui-colors-md-50-indigo   : 232, 234, 246;
    --ui-colors-md-100-indigo  : 197, 202, 233;
    --ui-colors-md-200-indigo  : 159, 168, 218;
    --ui-colors-md-300-indigo  : 121, 134, 203;
    --ui-colors-md-400-indigo  :  92, 107, 192;
    --ui-colors-md-500-indigo  :  63,  81, 181;
    --ui-colors-md-600-indigo  :  57,  73, 171;
    --ui-colors-md-700-indigo  :  48,  63, 159;
    --ui-colors-md-800-indigo  :  40,  53, 147;
    --ui-colors-md-900-indigo  :  26,  35, 126;
    --ui-colors-md-a100-indigo : 140, 158, 255;
    --ui-colors-md-a200-indigo :  83, 109, 254;
    --ui-colors-md-a400-indigo :  61,  90, 254;
    --ui-colors-md-a700-indigo :  48,  79, 254;

    --ui-colors-md-50-blue   : 227, 242, 253;
    --ui-colors-md-100-blue  : 187, 222, 251;
    --ui-colors-md-200-blue  : 144, 202, 249;
    --ui-colors-md-300-blue  : 100, 181, 246;
    --ui-colors-md-400-blue  :  66, 165, 245;
    --ui-colors-md-500-blue  :  33, 150, 243;
    --ui-colors-md-600-blue  :  30, 136, 229;
    --ui-colors-md-700-blue  :  25, 118, 210;
    --ui-colors-md-800-blue  :  21, 101, 192;
    --ui-colors-md-900-blue  :  13,  71, 161;
    --ui-colors-md-a100-blue : 130, 177, 255;
    --ui-colors-md-a200-blue :  68, 138, 255;
    --ui-colors-md-a400-blue :  41, 121, 255;
    --ui-colors-md-a700-blue :  41,  98, 255;

    --ui-colors-md-50-lightblue   : 225, 245, 254;
    --ui-colors-md-100-lightblue  : 179, 229, 252;
    --ui-colors-md-200-lightblue  : 129, 212, 250;
    --ui-colors-md-300-lightblue  :  79, 195, 247;
    --ui-colors-md-400-lightblue  :  41, 182, 246;
    --ui-colors-md-500-lightblue  :   3, 169, 244;
    --ui-colors-md-600-lightblue  :   3, 155, 229;
    --ui-colors-md-700-lightblue  :   2, 136, 209;
    --ui-colors-md-800-lightblue  :   2, 119, 189;
    --ui-colors-md-900-lightblue  :   1,  87, 155;
    --ui-colors-md-a100-lightblue : 128, 216, 255;
    --ui-colors-md-a200-lightblue :  64, 196, 255;
    --ui-colors-md-a400-lightblue :   0, 176, 255;
    --ui-colors-md-a700-lightblue :   0, 145, 234;

    --ui-colors-md-50-cyan   : 224, 247, 250;
    --ui-colors-md-100-cyan  : 178, 235, 242;
    --ui-colors-md-200-cyan  : 128, 222, 234;
    --ui-colors-md-300-cyan  :  77, 208, 225;
    --ui-colors-md-400-cyan  :  38, 198, 218;
    --ui-colors-md-500-cyan  :   0, 188, 212;
    --ui-colors-md-600-cyan  :   0, 172, 193;
    --ui-colors-md-700-cyan  :   0, 151, 167;
    --ui-colors-md-800-cyan  :   0, 131, 143;
    --ui-colors-md-900-cyan  :   0,  96, 100;
    --ui-colors-md-a100-cyan : 132, 255, 255;
    --ui-colors-md-a200-cyan :  24, 255, 255;
    --ui-colors-md-a400-cyan :   0, 229, 255;
    --ui-colors-md-a700-cyan :   0, 184, 212;

    --ui-colors-md-50-teal   : 224, 242, 241;
    --ui-colors-md-100-teal  : 178, 223, 219;
    --ui-colors-md-200-teal  : 128, 203, 196;
    --ui-colors-md-300-teal  :  77, 182, 172;
    --ui-colors-md-400-teal  :  38, 166, 154;
    --ui-colors-md-500-teal  :   0, 150, 136;
    --ui-colors-md-600-teal  :   0, 137, 123;
    --ui-colors-md-700-teal  :   0, 121, 107;
    --ui-colors-md-800-teal  :   0, 105,  92;
    --ui-colors-md-900-teal  :   0,  77,  64;
    --ui-colors-md-a100-teal : 167, 255, 235;
    --ui-colors-md-a200-teal : 100, 255, 218;
    --ui-colors-md-a400-teal :  29, 233, 182;
    --ui-colors-md-a700-teal :   0, 191, 165;

    --ui-colors-md-50-green   : 232, 245, 233;
    --ui-colors-md-100-green  : 200, 230, 201;
    --ui-colors-md-200-green  : 165, 214, 167;
    --ui-colors-md-300-green  : 129, 199, 132;
    --ui-colors-md-400-green  : 102, 187, 106;
    --ui-colors-md-500-green  :  76, 175,  80;
    --ui-colors-md-600-green  :  67, 160,  71;
    --ui-colors-md-700-green  :  56, 142,  60;
    --ui-colors-md-800-green  :  46, 125,  50;
    --ui-colors-md-900-green  :  27,  94,  32;
    --ui-colors-md-a100-green : 185, 246, 202;
    --ui-colors-md-a200-green : 105, 240, 174;
    --ui-colors-md-a400-green :   0, 230, 118;
    --ui-colors-md-a700-green :   0, 200,  83;

    --ui-colors-md-50-lightgreen   : 241, 248, 233;
    --ui-colors-md-100-lightgreen  : 220, 237, 200;
    --ui-colors-md-200-lightgreen  : 197, 225, 165;
    --ui-colors-md-300-lightgreen  : 174, 213, 129;
    --ui-colors-md-400-lightgreen  : 156, 204, 101;
    --ui-colors-md-500-lightgreen  : 139, 195,  74;
    --ui-colors-md-600-lightgreen  : 124, 179,  66;
    --ui-colors-md-700-lightgreen  : 104, 159,  56;
    --ui-colors-md-800-lightgreen  :  85, 139,  47;
    --ui-colors-md-900-lightgreen  :  51, 105,  30;
    --ui-colors-md-a100-lightgreen : 204, 255, 144;
    --ui-colors-md-a200-lightgreen : 178, 255,  89;
    --ui-colors-md-a400-lightgreen : 118, 255,   3;
    --ui-colors-md-a700-lightgreen : 100, 221,  23;

    --ui-colors-md-50-lime   : 249, 251, 231;
    --ui-colors-md-100-lime  : 240, 244, 195;
    --ui-colors-md-200-lime  : 230, 238, 156;
    --ui-colors-md-300-lime  : 220, 231, 117;
    --ui-colors-md-400-lime  : 212, 225,  87;
    --ui-colors-md-500-lime  : 205, 220,  57;
    --ui-colors-md-600-lime  : 192, 202,  51;
    --ui-colors-md-700-lime  : 175, 180,  43;
    --ui-colors-md-800-lime  : 158, 157,  36;
    --ui-colors-md-900-lime  : 130, 119,  23;
    --ui-colors-md-a100-lime : 244, 255, 129;
    --ui-colors-md-a200-lime : 238, 255,  65;
    --ui-colors-md-a400-lime : 198, 255,   0;
    --ui-colors-md-a700-lime : 174, 234,   0;

    --ui-colors-md-50-yellow   : 255, 253, 231;
    --ui-colors-md-100-yellow  : 255, 249, 196;
    --ui-colors-md-200-yellow  : 255, 245, 157;
    --ui-colors-md-300-yellow  : 255, 241, 118;
    --ui-colors-md-400-yellow  : 255, 238,  88;
    --ui-colors-md-500-yellow  : 255, 235,  59;
    --ui-colors-md-600-yellow  : 253, 216,  53;
    --ui-colors-md-700-yellow  : 251, 192,  45;
    --ui-colors-md-800-yellow  : 249, 168,  37;
    --ui-colors-md-900-yellow  : 245, 127,  23;
    --ui-colors-md-a100-yellow : 255, 255, 141;
    --ui-colors-md-a200-yellow : 255, 255,   0;
    --ui-colors-md-a400-yellow : 255, 234,   0;
    --ui-colors-md-a700-yellow : 255, 214,   0;

    --ui-colors-md-50-amber   : 255, 248, 225;
    --ui-colors-md-100-amber  : 255, 236, 179;
    --ui-colors-md-200-amber  : 255, 224, 130;
    --ui-colors-md-300-amber  : 255, 213,  79;
    --ui-colors-md-400-amber  : 255, 202,  40;
    --ui-colors-md-500-amber  : 255, 193,   7;
    --ui-colors-md-600-amber  : 255, 179,   0;
    --ui-colors-md-700-amber  : 255, 160,   0;
    --ui-colors-md-800-amber  : 255, 143,   0;
    --ui-colors-md-900-amber  : 255, 111,   0;
    --ui-colors-md-a100-amber : 255, 229, 127;
    --ui-colors-md-a200-amber : 255, 215,  64;
    --ui-colors-md-a400-amber : 255, 196,   0;
    --ui-colors-md-a700-amber : 255, 171,   0;

    --ui-colors-md-50-orange   : 255, 243, 224;
    --ui-colors-md-100-orange  : 255, 224, 178;
    --ui-colors-md-200-orange  : 255, 204, 128;
    --ui-colors-md-300-orange  : 255, 183,  77;
    --ui-colors-md-400-orange  : 255, 167,  38;
    --ui-colors-md-500-orange  : 255, 152,   0;
    --ui-colors-md-600-orange  : 251, 140,   0;
    --ui-colors-md-700-orange  : 245, 124,   0;
    --ui-colors-md-800-orange  : 239, 108,   0;
    --ui-colors-md-900-orange  : 230,  81,   0;
    --ui-colors-md-a100-orange : 255, 209, 128;
    --ui-colors-md-a200-orange : 255, 171,  64;
    --ui-colors-md-a400-orange : 255, 145,   0;
    --ui-colors-md-a700-orange : 255, 109,   0;

    --ui-colors-md-50-deeporange   : 251, 233, 231;
    --ui-colors-md-100-deeporange  : 255, 204, 188;
    --ui-colors-md-200-deeporange  : 255, 171, 145;
    --ui-colors-md-300-deeporange  : 255, 138, 101;
    --ui-colors-md-400-deeporange  : 255, 112,  67;
    --ui-colors-md-500-deeporange  : 255,  87,  34;
    --ui-colors-md-600-deeporange  : 244,  81,  30;
    --ui-colors-md-700-deeporange  : 230,  74,  25;
    --ui-colors-md-800-deeporange  : 216,  67,  21;
    --ui-colors-md-900-deeporange  : 191,  54,  12;
    --ui-colors-md-a100-deeporange : 255, 158, 128;
    --ui-colors-md-a200-deeporange : 255, 110,  64;
    --ui-colors-md-a400-deeporange : 255,  61,   0;
    --ui-colors-md-a700-deeporange : 221,  44,   0;

    --ui-colors-md-50-brown  : 239, 235, 233;
    --ui-colors-md-100-brown : 215, 204, 200;
    --ui-colors-md-200-brown : 188, 170, 164;
    --ui-colors-md-300-brown : 161, 136, 127;
    --ui-colors-md-400-brown : 141, 110,  99;
    --ui-colors-md-500-brown : 121,  85,  72;
    --ui-colors-md-600-brown : 109,  76,  65;
    --ui-colors-md-700-brown :  93,  64,  55;
    --ui-colors-md-800-brown :  78,  52,  46;
    --ui-colors-md-900-brown :  62,  39,  35;

    --ui-colors-md-50-grey  : 250, 250, 250;
    --ui-colors-md-100-grey : 245, 245, 245;
    --ui-colors-md-200-grey : 238, 238, 238;
    --ui-colors-md-300-grey : 224, 224, 224;
    --ui-colors-md-400-grey : 189, 189, 189;
    --ui-colors-md-500-grey : 158, 158, 158;
    --ui-colors-md-600-grey : 117, 117, 117;
    --ui-colors-md-700-grey :  97,  97,  97;
    --ui-colors-md-800-grey :  66,  66,  66;
    --ui-colors-md-900-grey :  33,  33,  33;

    --ui-colors-md-50-bluegrey  : 236, 239, 241;
    --ui-colors-md-100-bluegrey : 207, 216, 220;
    --ui-colors-md-200-bluegrey : 176, 190, 197;
    --ui-colors-md-300-bluegrey : 144, 164, 174;
    --ui-colors-md-400-bluegrey : 120, 144, 156;
    --ui-colors-md-500-bluegrey :  96, 125, 139;
    --ui-colors-md-600-bluegrey :  84, 110, 122;
    --ui-colors-md-700-bluegrey :  69,  90, 100;
    --ui-colors-md-800-bluegrey :  55,  71,  79;
    --ui-colors-md-900-bluegrey :  38,  50,  56;


    --ui-colors-camo-amber      : 255, 143,   0;
    --ui-colors-camo-blue       :  21, 101, 192;
    --ui-colors-camo-bluegrey   :  55,  71,  79;
    --ui-colors-camo-brown      :  78,  52,  46;
    --ui-colors-camo-cyan       :   0, 131, 143;
    --ui-colors-camo-deeporange : 216,  67,  21;
    --ui-colors-camo-deeppurple :  69,  39, 160;
    --ui-colors-camo-green      :  46, 125,  50;
    --ui-colors-camo-grey       :  66,  66,  66;
    --ui-colors-camo-indigo     :  40,  53, 147;
    --ui-colors-camo-lightblue  :   2, 119, 189;
    --ui-colors-camo-lightgreen :  85, 139,  47;
    --ui-colors-camo-lime       : 158, 157,  36;
    --ui-colors-camo-orange     : 239, 108,   0;
    --ui-colors-camo-pink       : 173,  20,  87;
    --ui-colors-camo-purple     : 106,  27, 154;
    --ui-colors-camo-red        : 198,  40,  40;
    --ui-colors-camo-teal       :   0, 105,  92;
    --ui-colors-camo-yellow     : 249, 168,  37;

    --ui-colors-fluor-amber      : 255, 196,   0;
    --ui-colors-fluor-blue       :  41, 121, 255;
    --ui-colors-fluor-cyan       :   0, 229, 255;
    --ui-colors-fluor-deeporange : 255,  61,   0;
    --ui-colors-fluor-deeppurple : 101,  31, 255;
    --ui-colors-fluor-green      :   0, 230, 118;
    --ui-colors-fluor-indigo     :  61,  90, 254;
    --ui-colors-fluor-lightblue  :   0, 176, 255;
    --ui-colors-fluor-lightgreen : 118, 255,   3;
    --ui-colors-fluor-lime       : 198, 255,   0;
    --ui-colors-fluor-orange     : 255, 145,   0;
    --ui-colors-fluor-pink       : 245,   0,  87;
    --ui-colors-fluor-purple     : 213,   0, 249;
    --ui-colors-fluor-red        : 255,  23,  68;
    --ui-colors-fluor-teal       :  29, 233, 182;
    --ui-colors-fluor-yellow     : 255, 234,   0;

    --ui-colors-metal-bronze     : 169, 113,  66;
    --ui-colors-metal-copper     : 203, 109,  81;
    --ui-colors-metal-gold       : 255, 215,   0;
    --ui-colors-metal-nickel     : 181, 182, 181;
    --ui-colors-metal-platinum   : 121, 121, 121;
    --ui-colors-metal-rust       : 195,  98,  65;
    --ui-colors-metal-silver     : 192, 192, 192;
    --ui-colors-metal-steel      :  95, 104, 110;
    --ui-colors-metal-titanium   : 135, 134, 129;
    --ui-colors-metal-white-gold : 255, 255, 244;

    --ui-colors-neutral-black :   0,   0,   0;
    --ui-colors-neutral-white : 255, 255, 255;


/********************************************************************
  UI
 ********************************************************************/
    --ui-border-radius    : 0.3rem;
    --ui-header-height    : 5.600rem;
    --ui-btn-radius       : 4.800rem;
    --ui-btn-nav          : 4.800rem;
    --ui-form-el          : 4.600rem;
    --ui-scroll-vh        : calc(100% - var(--ui-header-height));


    --ui-pad : 0.400rem;


    --ui-font-bold  : 600;


    --ui-color-base      :  30,  34,  39;
    --ui-color-bold      :  56,  60,  65;
    --ui-color-light     :  85,  89,  94;
    --ui-color-border    : 218, 222, 227;

    --ui-color-primary   :  26, 115, 232;
    --ui-color-secondary : 244, 180,   0;
    --ui-color-tertiary  :  15, 157,  88;

    --ui-color-bg-grey   : 248, 249, 250;


    --ui-color-failure : var(--ui-colors-md-a400-pink);
    --ui-color-warning : var(--ui-colors-md-a400-amber);
    --ui-color-info    : var(--ui-colors-md-a700-deeppurple);
    --ui-color-success : var(--ui-colors-md-800-cyan);
    --ui-color-form    : var(--ui-color-secondary);


    --ui-font-color           : rgba(var(--ui-color-base), 1);
    --ui-font-bold-color      : rgba(var(--ui-color-bold), 1);
    --ui-font-light-color     : rgba(var(--ui-color-light), 1);

    --ui-icon-color           : rgba(var(--ui-color-light), 1);
    --ui-border-color         : rgba(var(--ui-color-border), 1);
    --ui-border-color-form    : rgba(var(--ui-color-light), 0.266);

    --ui-link-color           : rgba(var(--ui-color-primary), 1);
    --ui-link-active-color    : rgba(var(--ui-color-base), 0.533);
    --ui-link-active-bg-color : rgba(var(--ui-color-border), 0.466);

    --ui-form-color           : rgba(var(--ui-color-form), 1);

    --ui-backdrop-color       : rgba(var(--ui-colors-neutral-black), 0.699);
    --ui-backdrop-spinner     : rgba(var(--ui-colors-neutral-white), 0.699);
    --ui-shadow-color         : rgba(var(--ui-color-base), 0.166);
    --ui-shadow-size          : calc(var(--ui-pad) * 1);
    --ui-shadow-card          : 0.0rem 0.0rem 0.0rem  0.1rem rgba(var(--ui-color-base), 0.056),
                                0.0rem 0.3rem 0.6rem -0.3rem rgba(var(--ui-color-base), 0.699);

    --ui-noise : repeating-radial-gradient(rgba(var(--ui-colors-md-50-grey), 0.050) 0 0.000096%, rgba(var(--ui-colors-md-900-grey), 0.040) 0 0.00011%);

}


@media (max-width: 1023px)
{
    :root
    {
        --ui-border-color-form : rgba(var(--ui-color-light), 0.333);
        --ui-noise : repeating-radial-gradient(rgba(var(--ui-colors-md-50-grey), 0.075) 0 0.000096%, rgba(var(--ui-colors-md-900-grey), 0.060) 0 0.00011%);
    }
}


:root
{
/********************************************************************
  UX
 ********************************************************************/

/*
    --ux-animation-x1 : 0.19;
    --ux-animation-y1 : 1.00;
    --ux-animation-x2 : 0.22;
    --ux-animation-y2 : 1.00;

    --ux-animation-decelerate         : cubic-bezier(var(--ux-animation-x1), var(--ux-animation-y1), var(--ux-animation-x2), var(--ux-animation-y2));
    --ux-animation-decelerate-reverse : cubic-bezier(calc(1 - var(--ux-animation-x2)), calc(1 - var(--ux-animation-y2)), calc(1 - var(--ux-animation-x1)), calc(1 - var(--ux-animation-y1)));

    --ux-animation-accelerate : cubic-bezier(var(--ux-animation-x1), var(--ux-animation-y1), var(--ux-animation-x2), var(--ux-animation-y2));
    --ux-animation-standard   : cubic-bezier(0.83, 0.00, 0.17, 1.00);


    --ux-animation-accelerate-ms  : 512ms;
    --ux-animation-decelerate-ms  : 640ms;
    --ux-animation-complex-ms     : 768ms;
    --ux-animation-too-complex-ms : 896ms; */

    /* 0.19, 1.00, 0.22, 1.00; */


    --ux-show         :  3.6rem;
    --ux-show-reverse : -3.6rem;

    --ux-motion-spinner-ms : 416ms;


    --ux-motion-standard               : cubic-bezier(0.2, 0, 0, 1);
    --ux-motion-standard-ms            : 320ms;

    --ux-motion-standard-decelerate    : cubic-bezier(0, 0, 0, 1);
    --ux-motion-standard-decelerate-ms : 288ms;

    --ux-motion-standard-accelerate    : cubic-bezier(0.3, 0, 1, 1);
    --ux-motion-standard-accelerate-ms : 224ms;


    --ux-motion-emphasized               : var(--ux-motion-standard);
    --ux-motion-emphasized-ms            : 512ms;

    --ux-motion-emphasized-decelerate    : cubic-bezier(0.05, 0.58, 0.1, 1); /* cubic-bezier(0.05, 0.7, 0.1, 1); */
    --ux-motion-emphasized-decelerate-ms : 416ms;

    --ux-motion-emphasized-accelerate    : cubic-bezier(0.3, 0, 0.74, 0.15); /* cubic-bezier(0.3, 0, 0.8, 0.15); */
    --ux-motion-emphasized-accelerate-ms : 224ms;
}


/********************************************************************
  DRAWER
 ********************************************************************/
:root
{
    --ui-drawer           :  32.000rem;
    --ui-drawer-reverse   : -32.000rem;
    --ui-drawer-transform :   2rem;
}


@media (max-width: 479px) and (orientation: portrait)
{
    :root
    {
        --ui-drawer         : calc(  100% - var(--ui-header-height));
        --ui-drawer-reverse : calc( -100% + var(--ui-header-height));
    }
}
